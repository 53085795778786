import {isTouch} from './objects_utils';



export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


export function randomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}


export function calculateStationInfo(objects) {
    let weight = 0;
    let cost = 0;
    let time = 0;
    let blocks = 0;
    objects.forEach(obj => {
        weight += obj.weight;
        cost += obj.cost;
        time += obj.time;
        blocks += 1;
    });
    return {weight: weight, cost: cost, time: time, blocks: blocks}
}


export function calculateGameInfo(objects, game_speed) {
    let aliens = 0;
    if (objects.length > 0) aliens = objects.length;

    return {aliens: aliens, game_speed: game_speed}
}


export function downloadFile(filename, text, type='json') {
    let element = document.createElement('a');
    if (type == 'json') {
        element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(text));
    } else {
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    }
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}


export function getObjectForStation(objects) {
    let blocks = [];
    objects.forEach(obj => {
        blocks.push({name: obj.name, position: {x: obj.position.x, y: obj.position.y, z: obj.position.z}});
    });
    return {
        'info': calculateStationInfo(objects),
        'blocks': blocks,
    }
}


export function validateStationConstruction(objects) {
    let problems = [];
    let isValid = true;
    let objectsWithoutConnections = [];
    let objectsNotStanding = [];
    // ToDo: intersected objects

    // check connections with others
    objects.forEach(obj => {
        let connected = false;
        objects.forEach(obj2 => {
            if (obj != obj2) {
                if (isTouch(obj, obj2)) connected = true;
            }
        });
        if (!connected) objectsWithoutConnections.push(obj);
    });

    // check staing on the floor ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    objectsWithoutConnections.forEach(obj => {
        if ((obj.position.y + obj.geometry.boundingBox.min.y).toFixed(2) > 0) {
            objectsNotStanding.push(obj);
        }
    });

    // process results and make error messages ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    objectsNotStanding.forEach(obj => {
        problems.push({
            obj: obj,
            level: 'critical',
            msg: 'hanging in the air (not standing on the floor or another object)'
        });
    });

    objectsWithoutConnections.forEach(obj => {
        problems.push({
            obj: obj,
            level: 'warning',
            msg: 'is not connected to other objects'
        });
    });

    if (problems.length > 0) isValid = false;

    return {
        isValid: isValid,
        problems: problems,
    }
}


export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}
























