<template>
    <div class="home-page">
        <div class="action-row">

            <div v-if="showSignUpForm" class="one-action">
                <div class="join-title">Join the Beta</div>
                <input id="email-input" type="text" placeholder="your@email.com" v-model="email" :class="isEmailValid()">
                <button id="sign-up-button" @click="signUp">Sign Up</button>
                <div v-if="notValidEmail" class="not-valid-email-message">
                    email is not valid
                </div>
            </div>

            <div v-if="signUpError" class="one-action error-message">
                An unexpected error has occurred. Please try again later.
            </div>

            <div v-if="signUpSucsses" class="one-action sucsses-message">
                Thank you for your interest! Your email address was added to the Beta test list. We will contact you when Beta testing begins.
            </div>

            <div class="one-action">
                <div class="">For a better understanding of what we are doing, you can try the live demo*:</div>
                <div class="demo-button-wrapper">
                    <button id="play-game-button" @click="goToGame">Play Game</button>
                    <button @click="goToDemo">Level Editor</button>
                </div>
                <div class="demo-warning-text">
                    * Not all functionality is available in demo mode, sign up for beta testing to be the first to access all features.
                </div>
            </div>

        </div>
    </div>
</template>



<script>
export default {
    data: function() {
        return {
            signUpError: false,
            signUpSucsses: false,
            showSignUpForm: true,
            notValidEmail: false,
            email: '',
            reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        }
    },
    watch: {
        email: function() {
            this.signUpError = false;
            this.notValidEmail = false;
            console.log(this.reg.test(this.email));
        },
    },
    methods: {
        goToGame: function() {
            this.$router.push('/game/');
        },
        goToDemo: function() {
            this.$router.push('/editor/');
        },
        isEmailValid: function() {
            return (this.email == "")? "" : (this.reg.test(this.email)) ? 'has-success' : 'has-error';
        },
        signUp: function() {
            if (!this.reg.test(this.email)) {
                this.notValidEmail = true;
                return;
            }

            this.$http.post('/api/beta_sign_up/', {'email': this.email})
                .then(response => {
                    return response.json();
                }, response => {
                    // error handler
                    console.log('Error:', response);
                    this.signUpError = true;
                    return 'error';
                })
                .then(data => {
                    if (data === 'error') return;
                    console.log('Sucssesful handler:', data);
                    this.showSignUpForm = false;
                    this.signUpSucsses = true;
            });
        },
    },
}
</script>



<style scoped>
.home-page {
    text-align: center;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
}
.action-row {
    margin: 50px auto;
    text-align: center;
    width: 300px;
}
.one-action {
    /* display: inline-block;
    width: 300px; */
    /* border: solid 1px orange; */
    text-align: left;
    margin: 50px 0;
}
#email-input {
    width: 210px;
}
#sign-up-button {
    width: 80px;
    cursor: pointer;
}
/*.join-title {
    color: #4bdcab;
}*/
.demo-button-wrapper {
    text-align: center;
    margin: 15px;
}
.demo-button-wrapper button {
    margin: 0 7px;
}
.demo-button-wrapper button:hover {
    cursor: pointer;
}
#play-game-button {
    /*cursor: no-drop;*/
}
.demo-warning-text {
    font-size: 80%;
    color: #999;
}
.sucsses-message,
.error-message {
    padding: 10px;
    border-radius: 4px;
    border: solid 1px #222;
}
.sucsses-message {
    background-color: #426d42;
}

.error-message {
    background-color: #9d4242;
}
.has-error {
    background-color: #ff9f9f;
}
.has-success {
    background-color: #97d696
}
.not-valid-email-message {
    font-size: 80%;
    color: #ff6272;
}
</style>
