<template>
    <div class="header">
        <h1 v-if="itsMain">DeltaVoid</h1>
        <h1 v-else><a href="/">DeltaVoid</a></h1>
        <div class="sub-title">
            3D space game about an alternative universe
        </div>
    </div>
</template>

<script>
export default {
    name: 'Header',
    computed: {
        itsMain: function() {
            if (this.$route.path == '/') return true;
            return false;
        },
    },
}
</script>


<style scoped>
.header {
    color: #3fb68e;
    background-color: #3f3f3f;
    padding: 15px;
    /* background-image: url('../assets/images/metallic-texture-background.jpg'); */
    /* border: ridge #616161; */
}
.header h1 {
    font-size: 220%;
    margin: 0;
    /* text-shadow: 1px 1px 1px #a7a7a7, 1px 1px 3px #000; */
    text-shadow: 1px 1px 1px #000, 1px 1px 2px #aaa;
}
.header h1 a {
    color: #3fb68e;
    text-decoration: none;
}
.sub-title {
    font-size: 130%;
    text-shadow: 1px 1px 1px #000, 1px 1px 2px #aaa;
}
</style>
