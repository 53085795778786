<template>
    <div class="right-column" ref="right-column" v-bind:style="{height: columnHeight + 'px'}">
        <div v-if=!gameStarted class=start-game-stuff>
            <div class=start-conditions>
                <span class="start-aliens-title">Number of Aliens:</span>
                <input v-model="numberOfAliens" type="number" step="1">
            </div>
            <button @click="startGame">Start Game</button>
        </div>
        <div v-else class="station-info">
            <div class="station-info-title">Game info</div>

            <div class="station-info-row">
                <span class="station-info-name-cell">Aliens:</span> {{ station.numberOfAliens }}
            </div>

            <div class="station-info-row">
                <span class="station-info-name-cell">Main Storage</span>
                <div class="smaller-with-indent">
                    <span  class="station-info-name-cell">ore:</span> {{ station.mainStorage.ore }}
                </div>
                <div class="smaller-with-indent">
                    <span  class="station-info-name-cell">gold:</span> {{ station.mainStorage.gold }}
                </div>
            </div>

            <div class="station-info-row">
                <span class="station-info-name-cell">Ore Factory</span>
                <div class="smaller-with-indent">
                    <span  class="station-info-name-cell">ore:</span> {{ station.oreFactory.ore }}
                </div>
                <div class="smaller-with-indent">
                    <span  class="station-info-name-cell">gold:</span> {{ station.oreFactory.gold }}
                </div>
            </div>

            <div class="station-info-row">
                <span class="station-info-name-cell">Gold Mine</span>
                <div class="smaller-with-indent">
                    <span  class="station-info-name-cell">ore:</span> {{ station.goldMine.ore }}
                </div>
            </div>

            <div class="pause-buttons">
                <button @click="pauseGame">
                    <span v-if="gamePused">Continue</span>
                    <span v-else>Pause</span>
                </button>
                <button v-if="gamePused" @click="makeOneStep">1 step forward</button>
            </div>

        </div>

        <div class="selected-object-info" v-if="selectedObject.name">
            <div class="selected-object-info-title">
                {{ capitalizeFirstLetter(selectedObject.name) }},
                <span class="selected-object-info-id">id: {{ selectedObject.id }}</span>
            </div>

            <div class="selected-object-info-group">
                <div class="coordinate-block">
                    <span class="coordinate-block-title">position:</span>
                    <span class="one-coordinate">{{ selectedObject.position.x.toFixed(1) }}</span>,
                    <span class="one-coordinate">{{ selectedObject.position.y.toFixed(1) }}</span>,
                    <span class="one-coordinate">{{ selectedObject.position.z.toFixed(1) }}</span>
                </div>

                <div class="coordinate-block">
                    <span class="coordinate-block-title">target:</span>
                    <span class="one-coordinate">{{ toGranularity(selectedObject.goals[0].x).toFixed(1) }}</span>,
                    <span class="one-coordinate">{{ toGranularity(selectedObject.goals[0].y).toFixed(1) }}</span>,
                    <span class="one-coordinate">{{ toGranularity(selectedObject.goals[0].z).toFixed(1) }}</span>
                </div>
            </div>

            <div class="selected-object-info-group">
                <div class="coordinate-block">
                    <span class="coordinate-block-title">next step:</span>
                    <span class="one-coordinate">{{ toGranularity(nextStep.x).toFixed(1) }}</span>,
                    <span class="one-coordinate">{{ toGranularity(nextStep.y).toFixed(1) }}</span>,
                    <span class="one-coordinate">{{ toGranularity(nextStep.z).toFixed(1) }}</span>
                </div>

                <div class="coordinate-block">
                    <span class="coordinate-block-title">alt. step:</span>
                    <span class="one-coordinate">{{ toGranularity(altNextStep.x).toFixed(1) }}</span>,
                    <span class="one-coordinate">{{ toGranularity(altNextStep.y).toFixed(1) }}</span>,
                    <span class="one-coordinate">{{ toGranularity(altNextStep.z).toFixed(1) }}</span>
                </div>
            </div>

            <div class="selected-object-info-group">
                <div>
                    <span class="obj-info-name-cell">cargo:</span> {{ selectedObject.cargo }}
                </div>
                <div>
                    <span class="obj-info-name-cell">health:</span> {{ selectedObject.health }}
                </div>
            </div>

            <div class="selected-object-info-group">
                <span class="obj-info-name-cell">next goal:</span> {{ selectedObject.goals[0].name }}
                <br>
                <span class="obj-info-name-cell">goals in queue:</span> {{ selectedObject.goals.length }}
            </div>

        </div>

        <div class="help-text">
            <div class="help-text-title">Help</div>
            <p>
                The goal of the game is to mine and recycle as much gold as possible while keeping as many aliens alive as possible.
            </p>

            <div class="legends">
                Legend:
                <div class="one-legend">
                    <span id="one-legend-alien"></span> – Alien
                </div>
                <div class="one-legend">
                    <span id="one-legend-mainstorage"></span> – Main Storage
                </div>
                <div class="one-legend">
                    <span id="one-legend-goldmine"></span> – Gold Mine
                </div>
                <div class="one-legend">
                    <span id="one-legend-orefactory"></span> – Ore Factory
                </div>
                <div class="one-legend">
                    <span id="one-legend-recreation"></span> – Recreation Zone
                </div>
            </div>

        </div>

        <ModalPopUp v-if="showModal">
            <div slot="header" class="modal-title">Import station from file</div>
            <div slot="body">
                <div class="modal-import-from-file">
                    <div>Please, select file:</div>
                    <input type="file" ref="myFile" @change="importFromFile">
                    <div v-if="fileWrongTypeError" class="file-wrong-type-error">
                        Error: wrong file type. It should be JSON.
                    </div>
                </div>
            </div>
            <div slot="footer" class="modal-import-from-file-footer">
                <button class="modal-default-button" @click="closePopUp">Cancel</button>
            </div>
        </ModalPopUp>

    </div>
</template>

<script>
import {eventBus} from '../main';
import {capitalizeFirstLetter} from '../utils';
import {toGranularity, getNextPosition, getNextAltPosition} from '../objects_utils';
import ModalPopUp from './ModalPopUp.vue';

export default {
    name: 'GameRightColumn',
    components: {
        ModalPopUp,
    },
    data: function() {
        return {
            columnHeight: 0,
            selectedObject: {
                position: {x: 0, y: 0, z: 0},
                goal: {x: 0, y: 0, z: 0}
            }, // make fake obj to avoid problems with watchers
            station: {
                aliens: 0,
                game_speed: 0,
            },
            numberOfAliens: 30,
            showModal: false,
            fileWrongTypeError: false,
            gameStarted: false,
            gamePused: false,
        }
    },
    watch: {
        'selectedObject.position.x': function(newValue) {
            this.selectedObject.position.x = toGranularity(newValue);
        },
        'selectedObject.position.y': function(newValue) {
            this.selectedObject.position.y = toGranularity(newValue);
        },
        'selectedObject.position.z': function(newValue) {
            this.selectedObject.position.z = toGranularity(newValue);
        },
        numberOfAliens: function(newValue) {
            if (newValue > 30) this.numberOfAliens = 30;
            if (newValue < 1) this.numberOfAliens = 1;
        },
    },
    computed: {
        nextStep() {
            let x = 0;
            let y = 0;
            let z = 0;

            const position = getNextPosition(this.selectedObject);
            x = position.x;
            y = position.y;
            z = position.z;

            return {x, y, z}
        },
        altNextStep() {
            let x, y, z = 0;

            const position = getNextAltPosition(this.selectedObject);
            x = position.x;
            y = position.y;
            z = position.z;

            return {x, y, z}
        },
    },
    mounted() {
        this.$nextTick(function() {
            window.addEventListener('resize', this.updateHeight);
            this.updateHeight();
        });

    },
    created: function() {
        eventBus.$on('selectingObject', (selectedObj) => {
            this.selectedObject = selectedObj;
        });
        eventBus.$on('unSelectingObject', () => {
            this.selectedObject = {position: {x: 0, y: 0, z: 0}};  // make fake obj to avoid problems with watchers
        });
        eventBus.$on('newStation', (station) => {
            this.station = station;
        });

        eventBus.$on('validateConstructionResult', (status) => {
            let infoForPopUp = {header: 'Construction status'};
            if (status.isValid) {
                infoForPopUp.body = '<div class="modal-construction-status-ok">Construction is valid!</div>';
            } else {
                let msg = '<div class="modal-construction-status-error">Construction is not valid:</div>';
                status.problems.forEach(problem => {
                    msg += '<div class="modal-construction-status-one-problem ' + problem.level + '">' + capitalizeFirstLetter(problem.obj.name) + ' (id ' + problem.obj.id + '): ' + problem.msg + '.</div>';
                });
                infoForPopUp.body = msg;
            }
            eventBus.$emit('showPopUp', infoForPopUp);
        });
    },
    methods: {
        updateHeight() {
            this.columnHeight = document.documentElement.clientHeight - 96;
        },
        startGame() {
            this.gameStarted = true;
            eventBus.$emit('startGame', this.numberOfAliens);
        },
        makeOneStep() {
            eventBus.$emit('makeOneStep');
        },
        capitalizeFirstLetter: function(string) {
            return capitalizeFirstLetter(string)
        },
        toGranularity: function(value) {
            return toGranularity(value);
        },
        validateConstruction: function() {
            eventBus.$emit('validateConstruction');
        },
        exportToFile: function() {
            eventBus.$emit('exportStationToFile');
        },
        pauseGame: function() {
            this.gamePused = !this.gamePused;
            eventBus.$emit('pauseGame', this.gamePused);
        },
        importFromFile: function() {
            // console.log('selected a file', this.$refs.myFile.files[0]);

            let file = this.$refs.myFile.files[0];
            if(!file || file.type !== 'application/json') {   // text/plain
                console.log('wrong type');
                this.fileWrongTypeError = true;
                return;
            }
            this.fileWrongTypeError = false;

            let reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = evt => {
                let text = evt.target.result;
                const obj = JSON.parse(text);
                // ToDo: validate json
                this.showModal = false;
                eventBus.$emit('autoBuildStation', obj.blocks);
            }
            reader.onerror = evt => {
                console.error(evt);
            }
        },
        closePopUp: function() {
            this.showModal = false;
        },
        deleteAll: function() {
            this.selectedObject = {position: {x: 0, y: 0, z: 0}};  // make fake obj to avoid problems with watchers
            eventBus.$emit('deleteAllBlocks');
        },
    },
}
</script>


<style scoped>
.right-column {
    /* width: 10%; */
    /* min-width: 200px; */
    color: #fcfbfe;
    background: #3f3f3f;
    text-align: left;
}
.position-inputs input {
    width: 53px;
}
.station-info {
    padding: 0 15px;
}

.selected-object-info {
    padding: 15px;
}
.station-info-row {
    padding-top: 2px;
    padding-bottom: 5px;
    border-bottom: dotted 1px #666;
}
/*.selected-object-info {
    margin-top: 10px;
}*/
.selected-object-info-title,
.station-info-title {
    margin-bottom: 5px;
}
.station-info-title,
.selected-object-info-title {
    text-align: center;
    font-weight: bold;
    text-shadow: 1px 1px 1px #000;
}
.position-info-title {
    display: inline-block;
    width: 20px;
}
.size-info-title {
    display: inline-block;
    width: 15px;
}
.station-info-name-cell,
.info-subtitles,
.obj-info-name-cell {
    color: #999;
}
.station-info-buttons {
    text-align: center;
    margin-top: 10px;
}
.station-info-buttons button {
    width: 150px;
    margin-bottom: 10px;
    cursor: pointer;
}
.selected-object-info-left-column,
.selected-object-info-right-column {
    display: inline-block;
}
.selected-object-info-left-column {
    width: 95px;
}
.selected-object-info-id {
    font-weight: normal;
    font-size: 80%;
    /* color: #999; */
}
.modal-title {
    color: #000000;
    text-align: center;
    font-size: 120%;
    font-weight: bold;
}
.modal-import-from-file {
    color: #000000;
}
.modal-import-from-file input {
    margin-top: 8px;
}
.modal-import-from-file-footer {
    text-align: right;
}
.modal-import-from-file-footer button {
    cursor: pointer;
}
.file-wrong-type-error {
    color: #b74d4d;
    font-size: 90%;
    margin-top: 15px;
    padding-left: 10px;
    border-left: solid 3px #b74d4d;
}
#delete-all-button {
    background-color: #ffafaf;
    border-color: #ffb6b6;
    border-radius: 2px;
}
.selected-object-additional-info {
    margin-top: 15px;
}
.start-game-stuff {
    text-align: center;
}
.start-conditions input {
    width: 36px;
    margin-left: 8px;
}
.start-game-stuff button {
    margin: 10px;
}
.legends {
    margin-top: 10px;
}
.one-legend {
    color: #999999;
}
#one-legend-alien {
    background-color: #33ff33;
    display: inline-block;
    width: 12px;
    height: 12px;
}
#one-legend-recreation,
#one-legend-goldmine,
#one-legend-orefactory,
#one-legend-mainstorage {
    display: inline-block;
    width: 12px;
    height: 12px;
}
#one-legend-recreation {
    background-color: #FF3333;
}
#one-legend-goldmine {
    background-color: #e5c100;
}
#one-legend-orefactory {
    background-color: #64a17e;
}
#one-legend-mainstorage {
    background-color: #b57124;
}
.pause-buttons {
    text-align: center;
    margin: 10px;
}
.pause-buttons button {
    margin: 5px;
}
.pause-buttons button:hover {
    cursor: pointer;
}
/* Help text ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.help-text {
    margin-top: 100px;
    font-size: 80%;
    padding: 10px;
    text-shadow: 1px 1px 1px #000;
}
.help-text p {
    text-align: left;
}
.help-text-title {
    text-align: center;
    font-size: 120%;
    font-weight: bold;
    /* color: #ba1e68; */
    text-shadow: 1px 1px 1px #000;
}
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.coordinate-block {

}
.coordinate-block-title {
    color: #999;
    display: inline-block;
    width: 71px;
    /*text-align: right;*/
}
.coordinate-block .one-coordinate {
    display: inline-block;
    width: 40px;
    text-align: right;
}
.selected-object-info-group {
    margin-bottom: 10px;
}
.smaller-with-indent {
    font-size: 90%;
    margin-left: 25px;
}
</style>


















