<template>
    <div id="left-column" class="left-column" ref="left-column" v-bind:style="{height: columnHeight + 'px'}">
        <div class="construction-blocks-title">Construction blocks for Space stations</div>
        <div class="tabs">
            <div class="one-tab" v-bind:class="activeTab == 'space' ? 'active' : ''" @click="activeTab = 'space'">Station 1</div>
            <div class="one-tab" v-bind:class="activeTab == 'warehouse' ? 'active' : ''" @click="activeTab = 'warehouse'">Station 2</div>
        </div>

        <div v-if="activeTab == 'space'" class="construction-blocks-space">
            <div class="construction-blocks">
                <div>Pillar</div>
                <div class="construction-blocks-img">
                    <img src="@/assets/images/thumbnail_pillar.png" @click.stop="addBlock('pillar')">
                </div>
            </div>
            <div class="construction-blocks">
                <div>Platform</div>
                <div class="construction-blocks-img">
                    <img src="@/assets/images/thumbnail_platform.png" @click.stop="addBlock('platform')">
                </div>
            </div>
            <div class="construction-blocks">
                <div>Box 1</div>
                <div class="construction-blocks-img">
                    <img src="@/assets/images/thumbnail_box_1.png" @click.stop="addBlock('box1')">
                </div>
            </div>
            <div class="construction-blocks">
                <div>Box 2</div>
                <div class="construction-blocks-img">
                    <img src="@/assets/images/thumbnail_box_2.png" @click.stop="addBlock('box2')">
                </div>
            </div>
        </div>

        <div v-if="activeTab == 'warehouse'" class="construction-blocks-warehouse">
            <div class="construction-blocks">
                <div>Rack Frame</div>
                <div class="construction-blocks-img">
                    <img src="@/assets/images/thumbnail_rack_frame.png" @click.stop="addBlock('frame')">
                </div>
            </div>
            <div class="construction-blocks">
                <div>Channel Beam</div>
                <div class="construction-blocks-img">
                    <img src="@/assets/images/thumbnail_channel_beam.png" @click.stop="addBlock('beam')">
                </div>
            </div>
            <div class="construction-blocks">
                <div>Мetal Decking</div>
                <div class="construction-blocks-img">
                    <img src="@/assets/images/thumbnail_metal_decking.png" @click.stop="addBlock('decking')">
                </div>
            </div>
            <div class="construction-blocks">
                <div>Row Spacer</div>
                <div class="construction-blocks-img">
                    <img src="@/assets/images/thumbnail_row_spacer.png" @click.stop="addBlock('spacer')">
                </div>
            </div>
            <div class="construction-blocks">
                <div>Box</div>
                <div class="construction-blocks-img">
                    <img src="@/assets/images/thumbnail_box_3.png" @click.stop="addBlock('box3')">
                </div>
            </div>
            <div class="construction-blocks">
                <div>Safety net</div>
                <div class="construction-blocks-img">
                    <img src="@/assets/images/thumbnail_safety_net.png" @click.stop="addBlock('net')">
                </div>
            </div>
        </div>

        <div class="help-text">
            <div class="help-text-title">Help</div>
            <p>Use a mouse with a left or right pressed button for <b>camera rotation/moving</b>.</p>
            <p>Also, you can use the "WASD" keys to <b>control the camera</b>.</p>
            <p>For <b>dragging objects</b> hold "Ctrl" + "Left mouse" buttons.</p>
            <div class="help-text-buttons-block">
                <button @click="buildExampleStation">Build example of Station 1</button>
                <button @click="buildExampleWarehouse">Build example of Station 2</button>
            </div>
        </div>
    </div>
</template>

<script>
import {eventBus, exampleOfBuildWarehouse} from '../main';

export default {
    name: 'LeftColumn',
    data: function() {
        return {
            columnHeight: 0,
            activeTab: 'space',
            leftColumn: null,
        }
    },
    mounted() {
        this.$nextTick(function() {
            this.leftColumn = document.getElementById('left-column');
            window.addEventListener('resize', this.updateHeight);
            this.updateHeight();
        });
    },
    methods: {
        updateHeight() {
            if (this.leftColumn.offsetHeight < document.documentElement.clientHeight) {
                this.columnHeight = document.documentElement.clientHeight - 96;
            }
        },
        addBlock: function(blockName) {
            eventBus.$emit('addNewBlock', blockName);
        },
        buildExampleStation: function() {
            eventBus.$emit('autoBuildStation');
        },
        buildExampleWarehouse: function() {
            eventBus.$emit('autoBuildStation', exampleOfBuildWarehouse);
        },
    },
}
</script>


<style scoped>
.left-column {
    position: relative;
    min-width: 200px;
    color: #fcfbfe;
    background: #3f3f3f;
    overflow: scroll;
}
.construction-blocks-warehouse,
.construction-blocks-space {
    min-height: 365px;
}
.construction-blocks-title {
    margin: 10px;
    font-weight: bold;
}
.construction-blocks {
    margin: 5px;
    display: inline-block;
    color: #999;
    font-size: 90%;
}
.construction-blocks-img {
    margin: 1px;
    display: inline-block;
    background-color: #fcfbfe;
    height: 86px;
}
.construction-blocks-img img {
    max-width: 80px;
    min-width: 80px;
    border: solid 3px #000000;
    cursor: pointer;
}
.construction-blocks-img img:hover,
.selected-img img {
    border: solid 3px #ba1e68;
}
/* Help text ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.help-text {
    margin-top: 100px;
    font-size: 80%;
    padding: 10px;
    text-shadow: 1px 1px 1px #000;
}
.help-text p {
    text-align: left;
}
.help-text-title {
    text-align: center;
    font-size: 120%;
    font-weight: bold;
    /* color: #ba1e68; */
    text-shadow: 1px 1px 1px #000;
}
.help-text-buttons-block {
    margin: 20px 0 30px 0;
}
.help-text button {
    cursor: pointer;
    width: 180px;
    margin-bottom: 15px;
}
/* Tabs ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.tabs {
    font-size: 90%;
    margin-bottom: 8px;
}
.one-tab {
    width: 83px;
    display: inline-block;
    margin: 0 10px;
    padding: 1px;
    cursor: pointer;
    background-color: #999;
    border-radius: 3px;
    border: 1px solid #333;
}
.one-tab.active {
    border: 1px solid #ba1e68;
}
</style>
