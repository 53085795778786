import Vue from 'vue'
import VueRouter from 'vue-router';
import VueResource from 'vue-resource';
import movable from "v-movable";

import App from './App.vue'
import Home from './components/Home.vue';
import Game from './components/Game.vue';
import Editor from './components/Editor.vue';
import Error404 from './components/Error404.vue';


const router = new VueRouter({
    mode: 'history',
    routes: [
        {path: '/', component: Home},
        {path: '/game/', component: Game},
        {path: '/editor/', component: Editor},
        {path: '*', component: Error404},
    ]
});


Vue.use(VueRouter);
Vue.use(VueResource);
Vue.use(movable);


export const eventBus = new Vue();
Vue.config.productionTip = false
Vue.http.options.root = process.env.VUE_APP_API_URL;

new Vue({
    render: h => h(App),
    router,
}).$mount('#app')



export const exampleOfBuildStation = [
    // ground ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    {name: 'platform', position: {x: 16.1, y: 0.1, z: 24}},
    {name: 'platform', position: {x: 16.1, y: 0.1, z: 28}},
    {name: 'platform', position: {x: 18.1, y: 0.1, z: 24}},
    {name: 'platform', position: {x: 18.1, y: 0.1, z: 28}},
    {name: 'platform', position: {x: 20.1, y: 0.1, z: 24}},
    {name: 'platform', position: {x: 20.1, y: 0.1, z: 28}},

    // first floor ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    {name: 'pillar', position: {x: 15.1, y: 2.2, z: 22}},
    {name: 'pillar', position: {x: 17.1, y: 2.2, z: 22}},

    {name: 'pillar', position: {x: 15.1, y: 2.2, z: 26}},
    {name: 'pillar', position: {x: 17.1, y: 2.2, z: 26}},

    {name: 'pillar', position: {x: 15.1, y: 2.2, z: 30}},
    {name: 'pillar', position: {x: 17.1, y: 2.2, z: 30}},

    {name: 'platform', position: {x: 16.1, y: 4.3, z: 24}},
    {name: 'platform', position: {x: 16.1, y: 4.3, z: 28}},

    {name: 'pillar', position: {x: 19.1, y: 2.2, z: 22}},
    {name: 'pillar', position: {x: 19.1, y: 2.2, z: 26}},
    {name: 'pillar', position: {x: 19.1, y: 2.2, z: 30}},

    {name: 'platform', position: {x: 18.1, y: 4.3, z: 24}},
    {name: 'platform', position: {x: 18.1, y: 4.3, z: 28}},

    {name: 'pillar', position: {x: 21.1, y: 2.2, z: 22}},
    {name: 'pillar', position: {x: 21.1, y: 2.2, z: 26}},
    {name: 'pillar', position: {x: 21.1, y: 2.2, z: 30}},

    {name: 'pillar', position: {x: 23, y: 2, z: 22.1}},     // for warning

    {name: 'platform', position: {x: 20.1, y: 4.3, z: 24}},
    {name: 'platform', position: {x: 20.1, y: 4.3, z: 28}},

    {name: 'box2', position: {x: 17.5, y: 0.7, z: 23.3}},
    {name: 'box1', position: {x: 20.6, y: 0.7, z: 22.7}},
    {name: 'box1', position: {x: 20.6, y: 1.7, z: 22.7}},
    {name: 'box1', position: {x: 20.6, y: 0.7, z: 23.7}},
    {name: 'box2', position: {x: 15.7, y: 0.7, z: 27.6}},
    {name: 'box1', position: {x: 15.7, y: 1.7, z: 27.1}},
    {name: 'box1', position: {x: 16.7, y: 0.7, z: 27.1}},
    {name: 'box1', position: {x: 19.6, y: 0.7, z: 27.5}},

    {name: 'box1', position: {x: 23.1, y: 0.8, z: 27.2}},   // for warning and error

    // second floor ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    {name: 'pillar', position: {x: 15.1, y: 6.2, z: 22}},
    {name: 'pillar', position: {x: 17.1, y: 6.2, z: 22}},

    {name: 'pillar', position: {x: 15.1, y: 6.2, z: 26}},
    {name: 'pillar', position: {x: 17.1, y: 6.2, z: 26}},

    {name: 'pillar', position: {x: 15.1, y: 6.2, z: 30}},
    {name: 'pillar', position: {x: 17.1, y: 6.2, z: 30}},

    {name: 'platform', position: {x: 16.1, y: 8.3, z: 24}},
    {name: 'platform', position: {x: 16.1, y: 8.3, z: 28}},

    {name: 'pillar', position: {x: 19.1, y: 6.2, z: 22}},
    {name: 'pillar', position: {x: 19.1, y: 6.2, z: 26}},
    {name: 'pillar', position: {x: 19.1, y: 6.2, z: 30}},

    {name: 'platform', position: {x: 18.1, y: 8.3, z: 24}},
    {name: 'platform', position: {x: 18.1, y: 8.3, z: 28}},

    {name: 'pillar', position: {x: 21.1, y: 6.2, z: 22}},
    {name: 'pillar', position: {x: 21.1, y: 6.2, z: 26}},
    {name: 'pillar', position: {x: 21.1, y: 6.2, z: 30}},

    {name: 'platform', position: {x: 20.1, y: 8.3, z: 24}},
    {name: 'platform', position: {x: 20.1, y: 8.3, z: 28}},

    {name: 'box2', position: {x: 20.3, y: 4.9, z: 23.2}},
    {name: 'box2', position: {x: 17.9, y: 4.9, z: 28}},
    {name: 'box1', position: {x: 15.5, y: 4.9, z: 24.8}},
    {name: 'box1', position: {x: 20.5, y: 4.9, z: 28.7}},

    // third floor ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    {name: 'box1', position: {x: 19.7, y: 8.9, z: 23.4}},
    {name: 'box1', position: {x: 18.7, y: 8.9, z: 23.4}},
    {name: 'box1', position: {x: 19.7, y: 8.9, z: 24.4}},
    {name: 'box1', position: {x: 19.7, y: 9.9, z: 23.4}},
    {name: 'box1', position: {x: 16.5, y: 8.9, z: 26.2}},
];


export const exampleOfBuildWarehouse = [
    // first row (0 for X) ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    {name: 'frame', position: {x: 10.6, y: 2.51, z: 13}},
    {name: 'frame', position: {x: 10.6, y: 2.51, z: 17.1}},
    {name: 'frame', position: {x: 10.6, y: 2.51, z: 21.2}},
    {name: 'frame', position: {x: 10.6, y: 2.51, z: 25.3}},

    {name: 'beam', position: {x: 10.05, y: 0.71, z: 15.05}},
    {name: 'beam', position: {x: 11.15, y: 0.71, z: 15.05}},
    {name: 'beam', position: {x: 10.05, y: 1.91, z: 15.05}},
    {name: 'beam', position: {x: 11.15, y: 1.91, z: 15.05}},
    {name: 'beam', position: {x: 10.05, y: 3.11, z: 15.05}},
    {name: 'beam', position: {x: 11.15, y: 3.11, z: 15.05}},
    {name: 'beam', position: {x: 10.05, y: 4.31, z: 15.05}},
    {name: 'beam', position: {x: 11.15, y: 4.31, z: 15.05}},

    {name: 'beam', position: {x: 10.05, y: 0.71, z: 19.15}},
    {name: 'beam', position: {x: 11.15, y: 0.71, z: 19.15}},
    {name: 'beam', position: {x: 10.05, y: 1.91, z: 19.15}},
    {name: 'beam', position: {x: 11.15, y: 1.91, z: 19.15}},
    {name: 'beam', position: {x: 10.05, y: 3.11, z: 19.15}},
    {name: 'beam', position: {x: 11.15, y: 3.11, z: 19.15}},
    {name: 'beam', position: {x: 10.05, y: 4.31, z: 19.15}},
    {name: 'beam', position: {x: 11.15, y: 4.31, z: 19.15}},

    {name: 'beam', position: {x: 10.05, y: 0.71, z: 23.25}},
    {name: 'beam', position: {x: 11.15, y: 0.71, z: 23.25}},
    {name: 'beam', position: {x: 10.05, y: 1.91, z: 23.25}},
    {name: 'beam', position: {x: 11.15, y: 1.91, z: 23.25}},
    {name: 'beam', position: {x: 10.05, y: 3.11, z: 23.25}},
    {name: 'beam', position: {x: 11.15, y: 3.11, z: 23.25}},
    {name: 'beam', position: {x: 10.05, y: 4.31, z: 23.25}},
    {name: 'beam', position: {x: 11.15, y: 4.31, z: 23.25}},

    {name: 'decking', position: {x: 10.6, y: 0.81, z: 15.05}},
    {name: 'decking', position: {x: 10.6, y: 2.01, z: 15.05}},
    {name: 'decking', position: {x: 10.6, y: 3.21, z: 15.05}},
    {name: 'decking', position: {x: 10.6, y: 4.41, z: 15.05}},

    {name: 'decking', position: {x: 10.6, y: 0.81, z: 19.15}},
    {name: 'decking', position: {x: 10.6, y: 2.01, z: 19.15}},
    {name: 'decking', position: {x: 10.6, y: 3.21, z: 19.15}},
    {name: 'decking', position: {x: 10.6, y: 4.41, z: 19.15}},

    {name: 'decking', position: {x: 10.6, y: 0.81, z: 23.25}},
    {name: 'decking', position: {x: 10.6, y: 2.01, z: 23.25}},
    {name: 'decking', position: {x: 10.6, y: 3.21, z: 23.25}},
    {name: 'decking', position: {x: 10.6, y: 4.41, z: 23.25}},

    {name: 'spacer', position: {x: 11.6, y: 0.71, z: 13}},
    {name: 'spacer', position: {x: 11.6, y: 1.91, z: 13}},
    {name: 'spacer', position: {x: 11.6, y: 3.11, z: 13}},
    {name: 'spacer', position: {x: 11.6, y: 4.31, z: 13}},

    {name: 'spacer', position: {x: 11.6, y: 0.71, z: 17.1}},
    {name: 'spacer', position: {x: 11.6, y: 1.91, z: 17.1}},
    {name: 'spacer', position: {x: 11.6, y: 3.11, z: 17.1}},
    {name: 'spacer', position: {x: 11.6, y: 4.31, z: 17.1}},

    {name: 'spacer', position: {x: 11.6, y: 0.71, z: 21.2}},
    {name: 'spacer', position: {x: 11.6, y: 1.91, z: 21.2}},
    {name: 'spacer', position: {x: 11.6, y: 3.11, z: 21.2}},
    {name: 'spacer', position: {x: 11.6, y: 4.31, z: 21.2}},

    {name: 'spacer', position: {x: 11.6, y: 0.71, z: 25.3}},
    {name: 'spacer', position: {x: 11.6, y: 1.91, z: 25.3}},
    {name: 'spacer', position: {x: 11.6, y: 3.11, z: 25.3}},
    {name: 'spacer', position: {x: 11.6, y: 4.31, z: 25.3}},

    // second row (+2 for X) ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    {name: 'frame', position: {x: 12.6, y: 2.51, z: 13}},
    {name: 'frame', position: {x: 12.6, y: 2.51, z: 17.1}},
    {name: 'frame', position: {x: 12.6, y: 2.51, z: 21.2}},
    {name: 'frame', position: {x: 12.6, y: 2.51, z: 25.3}},

    {name: 'beam', position: {x: 12.05, y: 0.71, z: 15.05}},
    {name: 'beam', position: {x: 13.15, y: 0.71, z: 15.05}},
    {name: 'beam', position: {x: 12.05, y: 1.91, z: 15.05}},
    {name: 'beam', position: {x: 13.15, y: 1.91, z: 15.05}},
    {name: 'beam', position: {x: 12.05, y: 3.11, z: 15.05}},
    {name: 'beam', position: {x: 13.15, y: 3.11, z: 15.05}},
    {name: 'beam', position: {x: 12.05, y: 4.31, z: 15.05}},
    {name: 'beam', position: {x: 13.15, y: 4.31, z: 15.05}},

    {name: 'beam', position: {x: 12.05, y: 0.71, z: 19.15}},
    {name: 'beam', position: {x: 13.15, y: 0.71, z: 19.15}},
    {name: 'beam', position: {x: 12.05, y: 1.91, z: 19.15}},
    {name: 'beam', position: {x: 13.15, y: 1.91, z: 19.15}},
    {name: 'beam', position: {x: 12.05, y: 3.11, z: 19.15}},
    {name: 'beam', position: {x: 13.15, y: 3.11, z: 19.15}},
    {name: 'beam', position: {x: 12.05, y: 4.31, z: 19.15}},
    {name: 'beam', position: {x: 13.15, y: 4.31, z: 19.15}},

    {name: 'beam', position: {x: 12.05, y: 0.71, z: 23.25}},
    {name: 'beam', position: {x: 13.15, y: 0.71, z: 23.25}},
    {name: 'beam', position: {x: 12.05, y: 1.91, z: 23.25}},
    {name: 'beam', position: {x: 13.15, y: 1.91, z: 23.25}},
    {name: 'beam', position: {x: 12.05, y: 3.11, z: 23.25}},
    {name: 'beam', position: {x: 13.15, y: 3.11, z: 23.25}},
    {name: 'beam', position: {x: 12.05, y: 4.31, z: 23.25}},
    {name: 'beam', position: {x: 13.15, y: 4.31, z: 23.25}},

    {name: 'decking', position: {x: 12.6, y: 0.81, z: 15.05}},
    {name: 'decking', position: {x: 12.6, y: 2.01, z: 15.05}},
    {name: 'decking', position: {x: 12.6, y: 3.21, z: 15.05}},
    {name: 'decking', position: {x: 12.6, y: 4.41, z: 15.05}},

    {name: 'decking', position: {x: 12.6, y: 0.81, z: 19.15}},
    {name: 'decking', position: {x: 12.6, y: 2.01, z: 19.15}},
    {name: 'decking', position: {x: 12.6, y: 3.21, z: 19.15}},
    {name: 'decking', position: {x: 12.6, y: 4.41, z: 19.15}},

    {name: 'decking', position: {x: 12.6, y: 0.81, z: 23.25}},
    {name: 'decking', position: {x: 12.6, y: 2.01, z: 23.25}},
    {name: 'decking', position: {x: 12.6, y: 3.21, z: 23.25}},
    {name: 'decking', position: {x: 12.6, y: 4.41, z: 23.25}},

    {name: 'spacer', position: {x: 13.6, y: 0.71, z: 13}},
    {name: 'spacer', position: {x: 13.6, y: 1.91, z: 13}},
    {name: 'spacer', position: {x: 13.6, y: 3.11, z: 13}},
    {name: 'spacer', position: {x: 13.6, y: 4.31, z: 13}},

    {name: 'spacer', position: {x: 13.6, y: 0.71, z: 17.1}},
    {name: 'spacer', position: {x: 13.6, y: 1.91, z: 17.1}},
    {name: 'spacer', position: {x: 13.6, y: 3.11, z: 17.1}},
    {name: 'spacer', position: {x: 13.6, y: 4.31, z: 17.1}},

    {name: 'spacer', position: {x: 13.6, y: 0.71, z: 21.2}},
    {name: 'spacer', position: {x: 13.6, y: 1.91, z: 21.2}},
    {name: 'spacer', position: {x: 13.6, y: 3.11, z: 21.2}},
    {name: 'spacer', position: {x: 13.6, y: 4.31, z: 21.2}},

    {name: 'spacer', position: {x: 13.6, y: 0.71, z: 25.3}},
    {name: 'spacer', position: {x: 13.6, y: 1.91, z: 25.3}},
    {name: 'spacer', position: {x: 13.6, y: 3.11, z: 25.3}},
    {name: 'spacer', position: {x: 13.6, y: 4.31, z: 25.3}},

    // third row (+2 for X) ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    {name: 'frame', position: {x: 14.6, y: 2.51, z: 13}},
    {name: 'frame', position: {x: 14.6, y: 2.51, z: 17.1}},
    {name: 'frame', position: {x: 14.6, y: 2.51, z: 21.2}},
    {name: 'frame', position: {x: 14.6, y: 2.51, z: 25.3}},

    {name: 'beam', position: {x: 14.05, y: 0.71, z: 15.05}},
    {name: 'beam', position: {x: 15.15, y: 0.71, z: 15.05}},
    {name: 'beam', position: {x: 14.05, y: 1.91, z: 15.05}},
    {name: 'beam', position: {x: 15.15, y: 1.91, z: 15.05}},
    {name: 'beam', position: {x: 14.05, y: 3.11, z: 15.05}},
    {name: 'beam', position: {x: 15.15, y: 3.11, z: 15.05}},
    {name: 'beam', position: {x: 14.05, y: 4.31, z: 15.05}},
    {name: 'beam', position: {x: 15.15, y: 4.31, z: 15.05}},

    {name: 'beam', position: {x: 14.05, y: 0.71, z: 19.15}},
    {name: 'beam', position: {x: 15.15, y: 0.71, z: 19.15}},
    {name: 'beam', position: {x: 14.05, y: 1.91, z: 19.15}},
    {name: 'beam', position: {x: 15.15, y: 1.91, z: 19.15}},
    {name: 'beam', position: {x: 14.05, y: 3.11, z: 19.15}},
    {name: 'beam', position: {x: 15.15, y: 3.11, z: 19.15}},
    {name: 'beam', position: {x: 14.05, y: 4.31, z: 19.15}},
    {name: 'beam', position: {x: 15.15, y: 4.31, z: 19.15}},

    {name: 'beam', position: {x: 14.05, y: 0.71, z: 23.25}},
    {name: 'beam', position: {x: 15.15, y: 0.71, z: 23.25}},
    {name: 'beam', position: {x: 14.05, y: 1.91, z: 23.25}},
    {name: 'beam', position: {x: 15.15, y: 1.91, z: 23.25}},
    {name: 'beam', position: {x: 14.05, y: 3.11, z: 23.25}},
    {name: 'beam', position: {x: 15.15, y: 3.11, z: 23.25}},
    {name: 'beam', position: {x: 14.05, y: 4.31, z: 23.25}},
    {name: 'beam', position: {x: 15.15, y: 4.31, z: 23.25}},

    {name: 'decking', position: {x: 14.6, y: 0.81, z: 15.05}},
    {name: 'decking', position: {x: 14.6, y: 2.01, z: 15.05}},
    {name: 'decking', position: {x: 14.6, y: 3.21, z: 15.05}},
    {name: 'decking', position: {x: 14.6, y: 4.41, z: 15.05}},

    {name: 'decking', position: {x: 14.6, y: 0.81, z: 19.15}},
    {name: 'decking', position: {x: 14.6, y: 2.01, z: 19.15}},
    {name: 'decking', position: {x: 14.6, y: 3.21, z: 19.15}},
    {name: 'decking', position: {x: 14.6, y: 4.41, z: 19.15}},

    {name: 'decking', position: {x: 14.6, y: 0.81, z: 23.25}},
    {name: 'decking', position: {x: 14.6, y: 2.01, z: 23.25}},
    {name: 'decking', position: {x: 14.6, y: 3.21, z: 23.25}},
    {name: 'decking', position: {x: 14.6, y: 4.41, z: 23.25}},

    {name: 'spacer', position: {x: 15.6, y: 0.71, z: 13}},
    {name: 'spacer', position: {x: 15.6, y: 1.91, z: 13}},
    {name: 'spacer', position: {x: 15.6, y: 3.11, z: 13}},
    {name: 'spacer', position: {x: 15.6, y: 4.31, z: 13}},

    {name: 'spacer', position: {x: 15.6, y: 0.71, z: 17.1}},
    {name: 'spacer', position: {x: 15.6, y: 1.91, z: 17.1}},
    {name: 'spacer', position: {x: 15.6, y: 3.11, z: 17.1}},
    {name: 'spacer', position: {x: 15.6, y: 4.31, z: 17.1}},

    {name: 'spacer', position: {x: 15.6, y: 0.71, z: 21.2}},
    {name: 'spacer', position: {x: 15.6, y: 1.91, z: 21.2}},
    {name: 'spacer', position: {x: 15.6, y: 3.11, z: 21.2}},
    {name: 'spacer', position: {x: 15.6, y: 4.31, z: 21.2}},

    {name: 'spacer', position: {x: 15.6, y: 0.71, z: 25.3}},
    {name: 'spacer', position: {x: 15.6, y: 1.91, z: 25.3}},
    {name: 'spacer', position: {x: 15.6, y: 3.11, z: 25.3}},
    {name: 'spacer', position: {x: 15.6, y: 4.31, z: 25.3}},

    // fourth row (+2 for X) ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    {name: 'frame', position: {x: 16.6, y: 2.51, z: 13}},
    {name: 'frame', position: {x: 16.6, y: 2.51, z: 17.1}},
    {name: 'frame', position: {x: 16.6, y: 2.51, z: 21.2}},
    {name: 'frame', position: {x: 16.6, y: 2.51, z: 25.3}},

    {name: 'beam', position: {x: 16.05, y: 0.71, z: 15.05}},
    {name: 'beam', position: {x: 17.15, y: 0.71, z: 15.05}},
    {name: 'beam', position: {x: 16.05, y: 1.91, z: 15.05}},
    {name: 'beam', position: {x: 17.15, y: 1.91, z: 15.05}},
    {name: 'beam', position: {x: 16.05, y: 3.11, z: 15.05}},
    {name: 'beam', position: {x: 17.15, y: 3.11, z: 15.05}},
    {name: 'beam', position: {x: 16.05, y: 4.31, z: 15.05}},
    {name: 'beam', position: {x: 17.15, y: 4.31, z: 15.05}},

    {name: 'beam', position: {x: 16.05, y: 0.71, z: 19.15}},
    {name: 'beam', position: {x: 17.15, y: 0.71, z: 19.15}},
    {name: 'beam', position: {x: 16.05, y: 1.91, z: 19.15}},
    {name: 'beam', position: {x: 17.15, y: 1.91, z: 19.15}},
    {name: 'beam', position: {x: 16.05, y: 3.11, z: 19.15}},
    {name: 'beam', position: {x: 17.15, y: 3.11, z: 19.15}},
    {name: 'beam', position: {x: 16.05, y: 4.31, z: 19.15}},
    {name: 'beam', position: {x: 17.15, y: 4.31, z: 19.15}},

    {name: 'beam', position: {x: 16.05, y: 0.71, z: 23.25}},
    {name: 'beam', position: {x: 17.15, y: 0.71, z: 23.25}},
    {name: 'beam', position: {x: 16.05, y: 1.91, z: 23.25}},
    {name: 'beam', position: {x: 17.15, y: 1.91, z: 23.25}},
    {name: 'beam', position: {x: 16.05, y: 3.11, z: 23.25}},
    {name: 'beam', position: {x: 17.15, y: 3.11, z: 23.25}},
    {name: 'beam', position: {x: 16.05, y: 4.31, z: 23.25}},
    {name: 'beam', position: {x: 17.15, y: 4.31, z: 23.25}},

    {name: 'decking', position: {x: 16.6, y: 0.81, z: 15.05}},
    {name: 'decking', position: {x: 16.6, y: 2.01, z: 15.05}},
    {name: 'decking', position: {x: 16.6, y: 3.21, z: 15.05}},
    {name: 'decking', position: {x: 16.6, y: 4.41, z: 15.05}},

    {name: 'decking', position: {x: 16.6, y: 0.81, z: 19.15}},
    {name: 'decking', position: {x: 16.6, y: 2.01, z: 19.15}},
    {name: 'decking', position: {x: 16.6, y: 3.21, z: 19.15}},
    {name: 'decking', position: {x: 16.6, y: 4.41, z: 19.15}},

    {name: 'decking', position: {x: 16.6, y: 0.81, z: 23.25}},
    {name: 'decking', position: {x: 16.6, y: 2.01, z: 23.25}},
    {name: 'decking', position: {x: 16.6, y: 3.21, z: 23.25}},
    {name: 'decking', position: {x: 16.6, y: 4.41, z: 23.25}},

    {name: 'spacer', position: {x: 17.6, y: 0.71, z: 13}},
    {name: 'spacer', position: {x: 17.6, y: 1.91, z: 13}},
    {name: 'spacer', position: {x: 17.6, y: 3.11, z: 13}},
    {name: 'spacer', position: {x: 17.6, y: 4.31, z: 13}},

    {name: 'spacer', position: {x: 17.6, y: 0.71, z: 17.1}},
    {name: 'spacer', position: {x: 17.6, y: 1.91, z: 17.1}},
    {name: 'spacer', position: {x: 17.6, y: 3.11, z: 17.1}},
    {name: 'spacer', position: {x: 17.6, y: 4.31, z: 17.1}},

    {name: 'spacer', position: {x: 17.6, y: 0.71, z: 21.2}},
    {name: 'spacer', position: {x: 17.6, y: 1.91, z: 21.2}},
    {name: 'spacer', position: {x: 17.6, y: 3.11, z: 21.2}},
    {name: 'spacer', position: {x: 17.6, y: 4.31, z: 21.2}},

    {name: 'spacer', position: {x: 17.6, y: 0.71, z: 25.3}},
    {name: 'spacer', position: {x: 17.6, y: 1.91, z: 25.3}},
    {name: 'spacer', position: {x: 17.6, y: 3.11, z: 25.3}},
    {name: 'spacer', position: {x: 17.6, y: 4.31, z: 25.3}},

    // fifth row (+2 for X) ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    {name: 'frame', position: {x: 18.6, y: 2.51, z: 13}},
    {name: 'frame', position: {x: 18.6, y: 2.51, z: 17.1}},
    {name: 'frame', position: {x: 18.6, y: 2.51, z: 21.2}},
    {name: 'frame', position: {x: 18.6, y: 2.51, z: 25.3}},

    {name: 'beam', position: {x: 18.05, y: 0.71, z: 15.05}},
    {name: 'beam', position: {x: 19.15, y: 0.71, z: 15.05}},
    {name: 'beam', position: {x: 18.05, y: 1.91, z: 15.05}},
    {name: 'beam', position: {x: 19.15, y: 1.91, z: 15.05}},
    {name: 'beam', position: {x: 18.05, y: 3.11, z: 15.05}},
    {name: 'beam', position: {x: 19.15, y: 3.11, z: 15.05}},
    {name: 'beam', position: {x: 18.05, y: 4.31, z: 15.05}},
    {name: 'beam', position: {x: 19.15, y: 4.31, z: 15.05}},

    {name: 'beam', position: {x: 18.05, y: 0.71, z: 19.15}},
    {name: 'beam', position: {x: 19.15, y: 0.71, z: 19.15}},
    {name: 'beam', position: {x: 18.05, y: 1.91, z: 19.15}},
    {name: 'beam', position: {x: 19.15, y: 1.91, z: 19.15}},
    {name: 'beam', position: {x: 18.05, y: 3.11, z: 19.15}},
    {name: 'beam', position: {x: 19.15, y: 3.11, z: 19.15}},
    {name: 'beam', position: {x: 18.05, y: 4.31, z: 19.15}},
    {name: 'beam', position: {x: 19.15, y: 4.31, z: 19.15}},

    {name: 'beam', position: {x: 18.05, y: 0.71, z: 23.25}},
    {name: 'beam', position: {x: 19.15, y: 0.71, z: 23.25}},
    {name: 'beam', position: {x: 18.05, y: 1.91, z: 23.25}},
    {name: 'beam', position: {x: 19.15, y: 1.91, z: 23.25}},
    {name: 'beam', position: {x: 18.05, y: 3.11, z: 23.25}},
    {name: 'beam', position: {x: 19.15, y: 3.11, z: 23.25}},
    {name: 'beam', position: {x: 18.05, y: 4.31, z: 23.25}},
    {name: 'beam', position: {x: 19.15, y: 4.31, z: 23.25}},

    {name: 'decking', position: {x: 18.6, y: 0.81, z: 15.05}},
    {name: 'decking', position: {x: 18.6, y: 2.01, z: 15.05}},
    {name: 'decking', position: {x: 18.6, y: 3.21, z: 15.05}},
    {name: 'decking', position: {x: 18.6, y: 4.41, z: 15.05}},

    {name: 'decking', position: {x: 18.6, y: 0.81, z: 19.15}},
    {name: 'decking', position: {x: 18.6, y: 2.01, z: 19.15}},
    {name: 'decking', position: {x: 18.6, y: 3.21, z: 19.15}},
    {name: 'decking', position: {x: 18.6, y: 4.41, z: 19.15}},

    {name: 'decking', position: {x: 18.6, y: 0.81, z: 23.25}},
    {name: 'decking', position: {x: 18.6, y: 2.01, z: 23.25}},
    {name: 'decking', position: {x: 18.6, y: 3.21, z: 23.25}},
    {name: 'decking', position: {x: 18.6, y: 4.41, z: 23.25}},

    {name: 'spacer', position: {x: 19.6, y: 0.71, z: 13}},
    {name: 'spacer', position: {x: 19.6, y: 1.91, z: 13}},
    {name: 'spacer', position: {x: 19.6, y: 3.11, z: 13}},
    {name: 'spacer', position: {x: 19.6, y: 4.31, z: 13}},

    {name: 'spacer', position: {x: 19.6, y: 0.71, z: 17.1}},
    {name: 'spacer', position: {x: 19.6, y: 1.91, z: 17.1}},
    {name: 'spacer', position: {x: 19.6, y: 3.11, z: 17.1}},
    {name: 'spacer', position: {x: 19.6, y: 4.31, z: 17.1}},

    {name: 'spacer', position: {x: 19.6, y: 0.71, z: 21.2}},
    {name: 'spacer', position: {x: 19.6, y: 1.91, z: 21.2}},
    {name: 'spacer', position: {x: 19.6, y: 3.11, z: 21.2}},
    {name: 'spacer', position: {x: 19.6, y: 4.31, z: 21.2}},

    {name: 'spacer', position: {x: 19.6, y: 0.71, z: 25.3}},
    {name: 'spacer', position: {x: 19.6, y: 1.91, z: 25.3}},
    {name: 'spacer', position: {x: 19.6, y: 3.11, z: 25.3}},
    {name: 'spacer', position: {x: 19.6, y: 4.31, z: 25.3}},

    // sixth row (+2 for X) ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    {name: 'frame', position: {x: 20.6, y: 2.51, z: 13}},
    {name: 'frame', position: {x: 20.6, y: 2.51, z: 17.1}},
    {name: 'frame', position: {x: 20.6, y: 2.51, z: 21.2}},
    {name: 'frame', position: {x: 20.6, y: 2.51, z: 25.3}},

    {name: 'beam', position: {x: 20.05, y: 0.71, z: 15.05}},
    {name: 'beam', position: {x: 21.15, y: 0.71, z: 15.05}},
    {name: 'beam', position: {x: 20.05, y: 1.91, z: 15.05}},
    {name: 'beam', position: {x: 21.15, y: 1.91, z: 15.05}},
    {name: 'beam', position: {x: 20.05, y: 3.11, z: 15.05}},
    {name: 'beam', position: {x: 21.15, y: 3.11, z: 15.05}},
    {name: 'beam', position: {x: 20.05, y: 4.31, z: 15.05}},
    {name: 'beam', position: {x: 21.15, y: 4.31, z: 15.05}},

    {name: 'beam', position: {x: 20.05, y: 0.71, z: 19.15}},
    {name: 'beam', position: {x: 21.15, y: 0.71, z: 19.15}},
    {name: 'beam', position: {x: 20.05, y: 1.91, z: 19.15}},
    {name: 'beam', position: {x: 21.15, y: 1.91, z: 19.15}},
    {name: 'beam', position: {x: 20.05, y: 3.11, z: 19.15}},
    {name: 'beam', position: {x: 21.15, y: 3.11, z: 19.15}},
    {name: 'beam', position: {x: 20.05, y: 4.31, z: 19.15}},
    {name: 'beam', position: {x: 21.15, y: 4.31, z: 19.15}},

    {name: 'beam', position: {x: 20.05, y: 0.71, z: 23.25}},
    {name: 'beam', position: {x: 21.15, y: 0.71, z: 23.25}},
    {name: 'beam', position: {x: 20.05, y: 1.91, z: 23.25}},
    {name: 'beam', position: {x: 21.15, y: 1.91, z: 23.25}},
    {name: 'beam', position: {x: 20.05, y: 3.11, z: 23.25}},
    {name: 'beam', position: {x: 21.15, y: 3.11, z: 23.25}},
    {name: 'beam', position: {x: 20.05, y: 4.31, z: 23.25}},
    {name: 'beam', position: {x: 21.15, y: 4.31, z: 23.25}},

    {name: 'decking', position: {x: 20.6, y: 0.81, z: 15.05}},
    {name: 'decking', position: {x: 20.6, y: 2.01, z: 15.05}},
    {name: 'decking', position: {x: 20.6, y: 3.21, z: 15.05}},
    {name: 'decking', position: {x: 20.6, y: 4.41, z: 15.05}},

    {name: 'decking', position: {x: 20.6, y: 0.81, z: 19.15}},
    {name: 'decking', position: {x: 20.6, y: 2.01, z: 19.15}},
    {name: 'decking', position: {x: 20.6, y: 3.21, z: 19.15}},
    {name: 'decking', position: {x: 20.6, y: 4.41, z: 19.15}},

    {name: 'decking', position: {x: 20.6, y: 0.81, z: 23.25}},
    {name: 'decking', position: {x: 20.6, y: 2.01, z: 23.25}},
    {name: 'decking', position: {x: 20.6, y: 3.21, z: 23.25}},
    {name: 'decking', position: {x: 20.6, y: 4.41, z: 23.25}},

    // Boxes ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    {name: 'box3', position: {x: 10.82, y: 1.11, z: 13.3}},
    {name: 'box3', position: {x: 10.35, y: 3.51, z: 13.85}},
    {name: 'box3', position: {x: 10.41, y: 1.11, z: 24.32}},
    {name: 'box3', position: {x: 12.51, y: 1.11, z: 23.42}},
    {name: 'box3', position: {x: 12.78, y: 1.11, z: 18.42}},
    {name: 'box3', position: {x: 14.41, y: 1.11, z: 25.12}},

    {name: 'box3', position: {x: 23.6, y: 0.25, z: 24}},
    {name: 'box3', position: {x: 24.1, y: 0.25, z: 24}},
    {name: 'box3', position: {x: 24.6, y: 0.25, z: 24}},
    {name: 'box3', position: {x: 23.6, y: 0.25, z: 24.5}},
    {name: 'box3', position: {x: 24.1, y: 0.25, z: 24.5}},
    {name: 'box3', position: {x: 24.6, y: 0.25, z: 24.5}},
    {name: 'box3', position: {x: 23.6, y: 0.25, z: 25}},
    {name: 'box3', position: {x: 24.1, y: 0.25, z: 25}},
    {name: 'box3', position: {x: 24.6, y: 0.25, z: 25}},
    {name: 'box3', position: {x: 20.36, y: 1.11, z: 24}},
    {name: 'box3', position: {x: 20.95, y: 2.31, z: 17.47}},

    {name: 'box3', position: {x: 23.6, y: 0.75, z: 24}},
    {name: 'box3', position: {x: 24.1, y: 0.75, z: 24}},
    {name: 'box3', position: {x: 24.6, y: 0.75, z: 24}},
    {name: 'box3', position: {x: 23.6, y: 0.75, z: 24.5}},
    {name: 'box3', position: {x: 24.1, y: 0.75, z: 24.5}},

    {name: 'box3', position: {x: 23.6, y: 1.25, z: 24}},

    {name: 'net', position: {x: 9.97, y: 0.75, z: 15.05}},
    {name: 'net', position: {x: 9.97, y: 1.95, z: 15.05}},
    {name: 'net', position: {x: 9.97, y: 3.15, z: 15.05}},
    {name: 'net', position: {x: 9.97, y: 0.75, z: 19.15}},
    {name: 'net', position: {x: 9.97, y: 1.95, z: 19.15}},
    {name: 'net', position: {x: 9.97, y: 3.15, z: 19.15}},
    {name: 'net', position: {x: 9.97, y: 0.75, z: 23.25}},
    {name: 'net', position: {x: 9.97, y: 1.95, z: 23.25}},
    {name: 'net', position: {x: 9.97, y: 3.15, z: 23.25}},

    {name: 'net', position: {x: 21.23, y: 0.75, z: 15.05}},
    {name: 'net', position: {x: 21.23, y: 1.95, z: 15.05}},
    {name: 'net', position: {x: 21.23, y: 3.15, z: 15.05}},
    {name: 'net', position: {x: 21.23, y: 0.75, z: 19.15}},
    {name: 'net', position: {x: 21.23, y: 1.95, z: 19.15}},
    {name: 'net', position: {x: 21.23, y: 3.15, z: 19.15}},
    {name: 'net', position: {x: 21.23, y: 0.75, z: 23.25}},
    {name: 'net', position: {x: 21.23, y: 1.95, z: 23.25}},
    {name: 'net', position: {x: 21.23, y: 3.15, z: 23.25}},

    {name: 'worker', position: {x: 25.5, y: 0.00001, z: 25}},

]


export const stationForGame = [
    // ground ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    {name: 'platform', position: {x: 4.1, y: 0.1, z: 16}},
    {name: 'platform', position: {x: 4.1, y: 0.1, z: 20}},
    {name: 'platform', position: {x: 6.1, y: 0.1, z: 16}},
    {name: 'platform', position: {x: 6.1, y: 0.1, z: 20}},
    {name: 'platform', position: {x: 8.1, y: 0.1, z: 16}},
    {name: 'platform', position: {x: 8.1, y: 0.1, z: 20}},

    // first floor ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    {name: 'pillar', position: {x: 3.1, y: 2.2, z: 14}},
    {name: 'pillar', position: {x: 5.1, y: 2.2, z: 14}},

    {name: 'pillar', position: {x: 3.1, y: 2.2, z: 18}},
    {name: 'pillar', position: {x: 5.1, y: 2.2, z: 18}},

    {name: 'pillar', position: {x: 3.1, y: 2.2, z: 22}},
    {name: 'pillar', position: {x: 5.1, y: 2.2, z: 22}},

    {name: 'platform', position: {x: 4.1, y: 4.3, z: 16}},
    {name: 'platform', position: {x: 4.1, y: 4.3, z: 20}},

    {name: 'pillar', position: {x: 7.1, y: 2.2, z: 14}},
    {name: 'pillar', position: {x: 7.1, y: 2.2, z: 18}},
    {name: 'pillar', position: {x: 7.1, y: 2.2, z: 22}},

    {name: 'platform', position: {x: 6.1, y: 4.3, z: 16}},
    {name: 'platform', position: {x: 6.1, y: 4.3, z: 20}},

    {name: 'pillar', position: {x: 9.1, y: 2.2, z: 14}},
    {name: 'pillar', position: {x: 9.1, y: 2.2, z: 18}},
    {name: 'pillar', position: {x: 9.1, y: 2.2, z: 22}},

    {name: 'platform', position: {x: 8.1, y: 4.3, z: 16}},
    {name: 'platform', position: {x: 8.1, y: 4.3, z: 20}},

    {name: 'box2', position: {x: 5.5, y: 0.7, z: 15.3}},
    {name: 'box1', position: {x: 8.6, y: 0.7, z: 14.7}},
    {name: 'box1', position: {x: 8.6, y: 1.7, z: 14.7}},
    {name: 'box1', position: {x: 8.6, y: 0.7, z: 15.7}},
    {name: 'box2', position: {x: 3.7, y: 0.7, z: 19.6}},
    {name: 'box1', position: {x: 3.7, y: 1.7, z: 19.1}},
    {name: 'box1', position: {x: 4.7, y: 0.7, z: 19.1}},
    {name: 'box1', position: {x: 7.6, y: 0.7, z: 19.5}},
];
