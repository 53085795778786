import * as THREE from 'three';
import { snapDistance, moveStep } from './objects';
import { getRandomInt } from './utils';
import { recreationZone, alienFullHealthLevel } from './logic';



export function toGranularity(val) {
    let f = parseFloat(val);
    if (!f) f = 0;
    return Math.round((Math.abs(f) + Number.EPSILON) * 100) / 100;
}


export function isCollision(obj_a, obj_b) {
    if (obj_a.position.x + obj_a.geometry.boundingBox.max.x < obj_b.position.x + obj_b.geometry.boundingBox.min.x) return false;
    if (obj_a.position.y + obj_a.geometry.boundingBox.max.y < obj_b.position.y + obj_b.geometry.boundingBox.min.y) return false;
    if (obj_a.position.z + obj_a.geometry.boundingBox.max.z < obj_b.position.z + obj_b.geometry.boundingBox.min.z) return false;
    if (obj_a.position.x + obj_a.geometry.boundingBox.min.x > obj_b.position.x + obj_b.geometry.boundingBox.max.x) return false;
    if (obj_a.position.y + obj_a.geometry.boundingBox.min.y > obj_b.position.y + obj_b.geometry.boundingBox.max.y) return false;
    if (obj_a.position.z + obj_a.geometry.boundingBox.min.z > obj_b.position.z + obj_b.geometry.boundingBox.max.z) return false;
    return true;
}


export function isTouch(obj_a, obj_b) {
    let intersectX = false;
    let intersectY = false;
    let intersectZ = false;

    if (obj_a.position.x + obj_a.geometry.boundingBox.max.x >= obj_b.position.x + obj_b.geometry.boundingBox.min.x) intersectX = true;
    if (obj_a.position.y + obj_a.geometry.boundingBox.max.y >= obj_b.position.y + obj_b.geometry.boundingBox.min.y) intersectY = true;
    if (obj_a.position.z + obj_a.geometry.boundingBox.max.z >= obj_b.position.z + obj_b.geometry.boundingBox.min.z) intersectZ = true;
    if (obj_a.position.x + obj_a.geometry.boundingBox.min.x > obj_b.position.x + obj_b.geometry.boundingBox.max.x) intersectX = false;
    if (obj_a.position.y + obj_a.geometry.boundingBox.min.y > obj_b.position.y + obj_b.geometry.boundingBox.max.y) intersectY = false;
    if (obj_a.position.z + obj_a.geometry.boundingBox.min.z > obj_b.position.z + obj_b.geometry.boundingBox.max.z) intersectZ = false;

    if (intersectX && intersectY && intersectZ) return true;
    return false;
}


export function fixIntersection(obj_a, obj_b) {
    let intersections = {x: 0, y: 0, z: 0};
    ['x', 'y', 'z'].forEach(axis => {
        if ((obj_a.position[axis] + obj_a.geometry.boundingBox.max[axis] > obj_b.position[axis] + obj_b.geometry.boundingBox.min[axis]) &&
            (obj_a.position[axis] + obj_a.geometry.boundingBox.min[axis] < obj_b.position[axis] + obj_b.geometry.boundingBox.max[axis])) {
                if (obj_a.position[axis] < obj_b.position[axis]) {
                    intersections[axis] = Math.abs((obj_a.position[axis] + obj_a.geometry.boundingBox.max[axis]) - (obj_b.position[axis] + obj_b.geometry.boundingBox.min[axis]));
            } else {
                intersections[axis] = Math.abs((obj_a.position[axis] + obj_a.geometry.boundingBox.min[axis]) - (obj_b.position[axis] + obj_b.geometry.boundingBox.max[axis]));
            }
        }
    });
    let minIntersection = 'x';
    if ((intersections.x < intersections.y) && (intersections.x < intersections.z)) minIntersection = 'x';
    if ((intersections.y < intersections.x) && (intersections.y < intersections.z)) minIntersection = 'y';
    if ((intersections.z < intersections.x) && (intersections.z < intersections.y)) minIntersection = 'z';

    if ((minIntersection == 'y') && ((obj_a.position.y - intersections.y) < 0)) {
        if (intersections.x < intersections.z) {
            minIntersection = 'x';
        } else {
            minIntersection = 'z';
        }
    }
    if (obj_a.position[minIntersection] < obj_b.position[minIntersection]) {
        obj_a.position[minIntersection] = obj_a.position[minIntersection] - intersections[minIntersection];
    } else {
        obj_a.position[minIntersection] = obj_a.position[minIntersection] + intersections[minIntersection];
    }
}


export function getNearestObjects(obj_a, constructionObjects) {
    let nearestObjects = [];
    let closestObj = null;
    const otherObjects = constructionObjects.filter(obj => obj != obj_a);
    let minDist = Number.MAX_SAFE_INTEGER;
    otherObjects.forEach(obj_b => {
        const distance = obj_a.position.distanceTo(obj_b.position);
        if (distance <= (obj_a.geometry.boundingSphere.radius + obj_b.geometry.boundingSphere.radius + snapDistance)) {
            nearestObjects.push(obj_b);
            if (distance < minDist) {
                closestObj = obj_b;
                minDist = distance;
            }
        }
    });
    return {all: nearestObjects, closest: closestObj};
}


export function getActiveSpots(obj_a, obj_b) {
    let activeSpots = [];
    let closestSpot = null;
    let moveVector = null;
    let minDist = Number.MAX_SAFE_INTEGER;
    obj_a.spots.forEach(spot_a => {
        const position_a = obj_a.position.clone().add(spot_a.position);
        obj_b.spots.forEach(spot_b => {
            if (spot_a.type == spot_b.type) {
                const position_b = obj_b.position.clone().add(spot_b.position);
                const distance = position_a.distanceTo(position_b);
                if (distance <= Math.max(spot_a.snap_distance, spot_b.snap_distance)) {
                    const activeSpot = {a: {spotID: spot_a.id}, b: {spotID: spot_b.id}};
                    activeSpots.push(activeSpot);
                    if (distance < minDist) {
                        closestSpot = activeSpot;
                        moveVector = position_b.clone().sub(position_a);
                    }
                }
            }
        });
    });
    return {all: activeSpots, closest: closestSpot, moveVector: moveVector};
}


export function applyPhysicalRestrictions(object, constructionObjects) {
    // moving granularity
    object.position.x = toGranularity(object.position.x);
    object.position.y = toGranularity(object.position.y);
    object.position.z = toGranularity(object.position.z);

    // sticky objects ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    const nearestObjects = getNearestObjects(object, constructionObjects);
    if (nearestObjects.closest) {
        const activeSpots = getActiveSpots(object, nearestObjects.closest);
        if(activeSpots.closest) {
            object.position.add(activeSpots.moveVector)
        }
    }

    // don't fall under the floor ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    if ((object.position.y + object.geometry.boundingBox.min.y) < 0) {
        object.position.y = Math.abs(object.geometry.boundingBox.min.y);
    }

    // Collision detection ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    const otherObjects = constructionObjects.filter(obj => obj != object);
    otherObjects.forEach(obj => {
        const detectedCollision = isCollision(object, obj);
        if (detectedCollision) {
            fixIntersection(object, obj);
        }
    });
}


export function getNextPosition(alien) {
    // return Vector3 where alien will be after success move
    const nextAlienPosition = alien.position.clone();   // THREE.Vector3()

    // detect coordinate with a bigger difference
    let max_coordinate = 'x';
    if (Math.abs(alien.position.z-alien.goals[0].z) > Math.abs(alien.position.x-alien.goals[0].x)) max_coordinate = 'z';

    // move to one step (0.1)
    if (alien.position[max_coordinate] > alien.goals[0][max_coordinate]) {
        nextAlienPosition[max_coordinate] = toGranularity(alien.position[max_coordinate] - moveStep);
    } else {
        nextAlienPosition[max_coordinate] = toGranularity(alien.position[max_coordinate] + moveStep);
    }
    return nextAlienPosition;
}


export function getNextAltPosition(alien) {
    // return Vector3 where alien will be after success move
    const nextPositioin = getNextPosition(alien);
    const nextAltPosition = alien.position.clone();   // THREE.Vector3()

    let max_coordinate = 'x';
    if (Math.abs(alien.position.z-alien.goals[0].z) > Math.abs(alien.position.x-alien.goals[0].x)) max_coordinate = 'z';

    const moveDelta = nextPositioin[max_coordinate] - alien.position[max_coordinate];
    let newMoveCoordinate = '';
    let newMoveDelta = 0;
    if (max_coordinate == 'x') {
        newMoveCoordinate = 'z';
        if (moveDelta > 0) {
            newMoveDelta = moveStep;
        } else {
            newMoveDelta = -1 * moveStep;
        }
    }
    if (max_coordinate == 'z') {
        newMoveCoordinate = 'x';
        if (moveDelta > 0) {
            newMoveDelta = -1 * moveStep;
        } else {
            newMoveDelta = moveStep;
        }
    }
    nextAltPosition[newMoveCoordinate] += newMoveDelta;
    return nextAltPosition;
}


export function makeNextMove(alien, otherObjects, station) {
    if (alien.goals.length == 0) return;
    if (alien.sleepTimer > 1) {
        alien.sleepTimer -= 1;
        return;
    }

    //  check if we have reached goal
    if (toGranularity(alien.position.x) == toGranularity(alien.goals[0].x) && toGranularity(alien.position.z) == toGranularity(alien.goals[0].z)) {

        // load the ore if we arrived at the mine
        if (alien.goals[0].id == 1) {
            if (alien.cargo == 'empty') {
                if (station.goldMine.ore > 1) {
                    station.goldMine.ore -=1;
                    alien.cargo = 'ore';
                } else {
                    console.log('Error: Gold Mine is empty. Alien can\'t load ore');
                }
            } else {
                console.log('Error: Alien didn\'t arrive to Mine empty. It cargo:', alien.cargo);
            }
        }

        // handle arriving to the Main Storage
        if (alien.goals[0].id == 2) {
            if (alien.cargo != 'empty') {
                if (alien.cargo == 'ore') {
                    station.mainStorage.ore +=1;
                }
                if (alien.cargo == 'gold') {
                    station.mainStorage.gold +=1;
                }
                alien.cargo = 'empty';
            }
            if (alien.goals[0].additionalAction == 'load ore') {
                station.mainStorage.ore -=1;
                alien.cargo = 'ore';
            }
        }

        // handle arriving to the Ore Factory
        if (alien.goals[0].id == 3) {
            if (alien.cargo == 'ore') {
                station.oreFactory.ore += 1;
                alien.cargo = 'empty';
            }
            if (alien.cargo == 'empty') {
                if (station.oreFactory.gold > 1) {
                    station.oreFactory.gold -= 1;
                    alien.cargo = 'gold';
                } else {
                    console.log('Error: Ore Factory is empty. Alien can\'t load gold');
                }
            }
            if (alien.cargo != 'ore' && alien.cargo != 'empty') {
                console.log('Error: Alien arrive to the Ore Factory with cargo:', alien.cargo);
            }
        }

        // restore health if we get to Recreation zone
        if (alien.goals[0].id == 4) alien.health = alienFullHealthLevel;

        alien.goals.shift();
        return;
    }

    const nextPositioin = getNextPosition(alien);
    let hasAbility = checkMoveAbility(alien, otherObjects);
    hasAbility = true;
    if (hasAbility) {
        // console.log('hasAbility', max_coordinate);
        alien.position = nextPositioin;
        alien.sleepTimer = 0;
        alien.health -= 1;
        if (alien.health < 600 && alien.goals[0].id != 4) alien.goals.unshift(new recreationZone());
    } else {
        const nextAlienPosition = getNextAltPosition(alien);

        const hasSecondAbility = checkMoveAbility(alien, otherObjects, nextAlienPosition)
        if (hasSecondAbility) {
            alien.position = nextAlienPosition;
            alien.sleepTimer = 0;
        } else {
            alien.sleepTimer = getRandomInt(5, 10);
        }

        // console.log(nextPositioin[max_coordinate] - alien.position[max_coordinate], nextPositioin, alien.position);
    }
}


export function checkMoveAbility(alien, otherObjects, nextAlienPosition=null) {
    let canMove = true;
    if (!nextAlienPosition) nextAlienPosition = getNextPosition(alien);

    // quick check
    otherObjects.forEach(obj_b => {
        if (alien !== obj_b) {
            const distance = nextAlienPosition.distanceTo(obj_b.position);
            if (distance <= (alien.geometry.boundingSphere.radius + obj_b.geometry.boundingSphere.radius + moveStep)) {
                canMove = false;
                // console.log('FUCK!', distance, alien.geometry.boundingSphere.radius, obj_b.geometry.boundingSphere.radius, alien.position, obj_b.position);
            }
        }
    });

    return canMove;
}


export function getCenterPoint(mesh) {
    const geometry = mesh.geometry;
    geometry.computeBoundingBox();
    const center = new THREE.Vector3();
    geometry.boundingBox.getCenter( center );
    mesh.localToWorld( center );
    return center;
}























