/*
List of goals:
    "mineOreGoal" - move ore from Gold Mine to Main Storage
    "refineOreGoal" - move ore from Main Storage to Ore Factory
    "restGoal" - go to Recreation

goal = {
    destination: {x, y, z},
    time: 0,
}
*/

import { getRandomInt } from './utils';


export const alienFullHealthLevel = 5000;


// Goals ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export class goldMine {
    id = 1;
    name = 'Gold Mine';
    x = 36;
    y = 0.16;
    z = 38;
    duration = 100;
    additionalAction = null;
}


export class mainStorage {
    id = 2;
    name = 'Main Storage';
    x = 6;
    y = 0.16;
    z = 18;
    duration = 100;
    additionalAction = null;
}


export class oreFactory {
    id = 3;
    name = 'Ore Factory';
    x = 36;
    y = 0.16;
    z = 3;
    duration = 100;
    additionalAction = null;
}


export class recreationZone {
    id = 4;
    name = 'Recreation Zone';
    x = 3;
    y = 0.16;
    z = 38;
    duration = 100;
    additionalAction = null;
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export class Station {
    goldMine = {
        ore: getRandomInt(500, 1000),
        aliensInside: 0,
    };
    mainStorage = {
        ore: 0,
        gold: 0,
        aliensInside: 0,
    };
    oreFactory = {
        ore: 0,
        gold: 0,
        aliensInside: 0,

        cycleCounter: 0,
        produceNow: true,
    };
    recreationZone = {
        aliensInside: 0,
    };

    oreFactoryProduceGold() {
        if (this.oreFactory.ore < 1) {
            this.oreFactory.cycleCounter = 0;
            return;
        }
        if (this.oreFactory.produceNow) {
            if (this.oreFactory.cycleCounter > 150) {
                this.oreFactory.ore -= 1;
                this.oreFactory.gold += 1;
                this.oreFactory.cycleCounter = 0;
            }
            this.oreFactory.cycleCounter += 1;
        }
    }
}


export class SuperIntelligence {
    constructor(aliens) {
        this.aliens = aliens;
    }

    getNewGoals() {
        const i = getRandomInt(1, 4)
        let nextGoal = null;
        switch (i) {
        case 1:
            nextGoal = new goldMine();
            break;
        case 2:
            nextGoal = new mainStorage();
            break;
        case 3:
            nextGoal = new oreFactory();
            break;
        case 4:
            nextGoal = new recreationZone();
            break;
        default:
            nextGoal = new recreationZone();
        }

        return [nextGoal];
    }
}























