<template>
    <div class="right-column" ref="right-column" v-bind:style="{height: columnHeight + 'px'}">
        <div class="station-info">
            <div class="station-info-title">Construction info</div>
            <div class="station-info-row">
                <span class="station-info-name-cell">weight:</span> {{ toGranularity(station.weight) }} teraxins
            </div>
            <div class="station-info-row">
                <span class="station-info-name-cell">cost:</span> {{ toGranularity(station.cost) }} gold coins
            </div>
            <div class="station-info-row">
                <span class="station-info-name-cell">constr. time:</span> {{ toGranularity(station.time) }} hours
            </div>
            <div class="station-info-row">
                <span class="station-info-name-cell">objects:</span> {{ station.blocks }} blocks
            </div>
            <div class="station-info-buttons">
                <button @click="validateConstruction">Validate construction</button>
                <button @click="exportToFile">Export to file</button>
                <button @click="showModal = true">Import from file</button>
                <button @click="deleteAll" id="delete-all-button">Delete all blocks</button>
            </div>
        </div>

        <div class="selected-object-info" v-if="selectedObject.name">
            <div class="selected-object-info-title">
                {{ capitalizeFirstLetter(selectedObject.name) }},
                <span class="selected-object-info-id">id: {{ selectedObject.id }}</span>
            </div>
            <div class="selected-object-info-left-column">
                <span class="info-subtitles">position</span>
                <div class="position-inputs">
                    <div>
                        <span class="position-info-title">x:</span>
                        <input v-model="selectedObject.position.x" type="number" step="0.01">
                    </div>
                    <div>
                        <span class="position-info-title">y:</span>
                        <input v-model="selectedObject.position.y" type="number" step="0.01">
                    </div>
                    <div>
                        <span class="position-info-title">z:</span>
                        <input v-model="selectedObject.position.z" type="number" step="0.01">
                    </div>
                </div>
            </div>
            <div class="selected-object-info-right-column">
                <span class="info-subtitles">size</span>
                <div class="size-info">
                    <div>
                        <span class="size-info-title">width (x):</span>
                        {{ toGranularity(Math.abs(selectedObject.geometry.boundingBox.min.x) +  Math.abs(selectedObject.geometry.boundingBox.max.x)) }}
                    </div>
                    <div>
                        <span class="size-info-title">height (y):</span>
                        {{ toGranularity(Math.abs(selectedObject.geometry.boundingBox.min.y) +  Math.abs(selectedObject.geometry.boundingBox.max.y)) }}
                    </div>
                    <div>
                        <span class="size-info-title">depth (z):</span>
                        {{ toGranularity(Math.abs(selectedObject.geometry.boundingBox.min.z) +  Math.abs(selectedObject.geometry.boundingBox.max.z)) }}
                    </div>
                </div>
            </div>
            <div class="selected-object-additional-info">
                <div>
                    <span class="obj-info-name-cell">weight:</span> {{ selectedObject.weight }} kg
                </div>
                <div>
                    <span class="obj-info-name-cell">price:</span> ${{ selectedObject.cost }}
                </div>
                <div>
                    <span class="obj-info-name-cell">installation time:</span> {{ selectedObject.time }} h
                </div>
            </div>
        </div>

        <ModalPopUp v-if="showModal">
            <div slot="header" class="modal-title">Import station from file</div>
            <div slot="body">
                <div class="modal-import-from-file">
                    <div>Please, select file:</div>
                    <input type="file" ref="myFile" @change="importFromFile">
                    <div v-if="fileWrongTypeError" class="file-wrong-type-error">
                        Error: wrong file type. It should be JSON.
                    </div>
                </div>
            </div>
            <div slot="footer" class="modal-import-from-file-footer">
                <button class="modal-default-button" @click="closePopUp">Cancel</button>
            </div>
        </ModalPopUp>

    </div>
</template>

<script>
import {eventBus} from '../main';
import {capitalizeFirstLetter} from '../utils';
import {toGranularity} from '../objects_utils';
import ModalPopUp from './ModalPopUp.vue';

export default {
    name: 'RightColumn',
    components: {
        ModalPopUp,
    },
    data: function() {
        return {
            columnHeight: 0,
            selectedObject: {position: {x: 0, y: 0, z: 0}}, // make fake obj to avoid problems with watchers
            station: {
                weight: 0,
                cost: 0,
                time: 0,
                blocks: 0,
            },
            showModal: false,
            fileWrongTypeError: false,
        }
    },
    watch: {
        'selectedObject.position.x': function(newValue) {
            this.selectedObject.position.x = toGranularity(newValue);
        },
        'selectedObject.position.y': function(newValue) {
            this.selectedObject.position.y = toGranularity(newValue);
        },
        'selectedObject.position.z': function(newValue) {
            this.selectedObject.position.z = toGranularity(newValue);
        },
    },
    mounted() {
        this.$nextTick(function() {
            window.addEventListener('resize', this.updateHeight);
            this.updateHeight();
        });

    },
    created: function() {
        eventBus.$on('selectingObject', (selectedObj) => {
            this.selectedObject = selectedObj;
        });
        eventBus.$on('unSelectingObject', () => {
            this.selectedObject = {position: {x: 0, y: 0, z: 0}};  // make fake obj to avoid problems with watchers
        });
        eventBus.$on('newStationInfo', (info) => {
            this.station.weight = info.weight;
            this.station.cost = info.cost;
            this.station.time = info.time;
            this.station.blocks = info.blocks;
        });

        eventBus.$on('validateConstructionResult', (status) => {
            let infoForPopUp = {header: 'Construction status'};
            if (status.isValid) {
                infoForPopUp.body = '<div class="modal-construction-status-ok">Construction is valid!</div>';
            } else {
                let msg = '<div class="modal-construction-status-error">Construction is not valid:</div>';
                status.problems.forEach(problem => {
                    msg += '<div class="modal-construction-status-one-problem ' + problem.level + '">' + capitalizeFirstLetter(problem.obj.name) + ' (id ' + problem.obj.id + '): ' + problem.msg + '.</div>';
                });
                infoForPopUp.body = msg;
            }
            eventBus.$emit('showPopUp', infoForPopUp);
        });
    },
    methods: {
        updateHeight() {
            this.columnHeight = document.documentElement.clientHeight - 96;
        },
        capitalizeFirstLetter: function(string) {
            return capitalizeFirstLetter(string)
        },
        toGranularity: function(value) {
            return toGranularity(value);
        },
        validateConstruction: function() {
            eventBus.$emit('validateConstruction');
        },
        exportToFile: function() {
            eventBus.$emit('exportStationToFile');
        },
        importFromFile: function() {
            // console.log('selected a file', this.$refs.myFile.files[0]);

            let file = this.$refs.myFile.files[0];
            if(!file || file.type !== 'application/json') {   // text/plain
                console.log('wrong type');
                this.fileWrongTypeError = true;
                return;
            }
            this.fileWrongTypeError = false;

            let reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = evt => {
                let text = evt.target.result;
                const obj = JSON.parse(text);
                // ToDo: validate json
                this.showModal = false;
                eventBus.$emit('autoBuildStation', obj.blocks);
            }
            reader.onerror = evt => {
                console.error(evt);
            }
        },
        closePopUp: function() {
            this.showModal = false;
        },
        deleteAll: function() {
            this.selectedObject = {position: {x: 0, y: 0, z: 0}};  // make fake obj to avoid problems with watchers
            eventBus.$emit('deleteAllBlocks');
        },
    },
}
</script>


<style scoped>
.right-column {
    /* width: 10%; */
    /* min-width: 200px; */
    color: #fcfbfe;
    background: #3f3f3f;
    text-align: left;
}
.position-inputs input {
    width: 53px;
}
.station-info,
.selected-object-info {
    padding: 15px;
}
.selected-object-info {
    margin-top: 10px;
}
.selected-object-info-title,
.station-info-title {
    margin-bottom: 5px;
}
.station-info-title,
.selected-object-info-title {
    text-align: center;
    font-weight: bold;
    text-shadow: 1px 1px 1px #000;
}
.position-info-title {
    display: inline-block;
    width: 20px;
}
.size-info-title {
    display: inline-block;
    width: 75px;
}
.station-info-name-cell,
.info-subtitles,
.obj-info-name-cell {
    color: #999;
}
.station-info-buttons {
    text-align: center;
    margin-top: 10px;
}
.station-info-buttons button {
    width: 150px;
    margin-bottom: 10px;
    cursor: pointer;
}
.selected-object-info-left-column,
.selected-object-info-right-column {
    display: inline-block;
}
.selected-object-info-left-column {
    width: 95px;
}
.selected-object-info-id {
    font-weight: normal;
    font-size: 80%;
    /* color: #999; */
}
.modal-title {
    color: #000000;
    text-align: center;
    font-size: 120%;
    font-weight: bold;
}
.modal-import-from-file {
    color: #000000;
}
.modal-import-from-file input {
    margin-top: 8px;
}
.modal-import-from-file-footer {
    text-align: right;
}
.modal-import-from-file-footer button {
    cursor: pointer;
}
.file-wrong-type-error {
    color: #b74d4d;
    font-size: 90%;
    margin-top: 15px;
    padding-left: 10px;
    border-left: solid 3px #b74d4d;
}
#delete-all-button {
    background-color: #ffafaf;
    border-color: #ffb6b6;
    border-radius: 2px;
}
.selected-object-additional-info {
    margin-top: 15px;
}
</style>
