<template>
    <div class="canvas" ref="canvas" v-bind:style="{height: columnHeight + 'px'}">
        <div id="place-for-canvas"></div>

        <div v-show="rightClickedObject" id="object-popup" ref="parentEl">
            <movable class="object-popup-title" target="parentEl">
                {{ capitalizeFirstLetter(rightClickedObject.name) }}, id: {{ rightClickedObject.id }}
            </movable>
            <span id="object-popup-close" @click="closeObjectPopUp">x</span>
            <div class="right-clicked-object-info-left-column">
                <div class="position-inputs-title">position</div>
                <div class="position-inputs">
                    <div>
                        <span class="position-info-title">x:</span>
                        <input v-model="rightClickedObject.position.x" type="number" step="0.01">
                    </div>
                    <div>
                        <span class="position-info-title">y:</span>
                        <input v-model="rightClickedObject.position.y" type="number" step="0.01">
                    </div>
                    <div>
                        <span class="position-info-title">z:</span>
                        <input v-model="rightClickedObject.position.z" type="number" step="0.01">
                    </div>
                </div>
            </div>
            <div class="right-clicked-object-info-right-column">
                <div class="position-inputs-title-right">size</div>
                <div>
                    <div class="right-clicked-popup-one-row">
                        <span>width:</span>
                        {{ toGranularity(Math.abs(rightClickedObject.geometry.boundingBox.min.x) +  Math.abs(rightClickedObject.geometry.boundingBox.max.x)) }}
                    </div>
                    <div class="right-clicked-popup-one-row">
                        <span>height:</span>
                        {{ toGranularity(Math.abs(rightClickedObject.geometry.boundingBox.min.y) +  Math.abs(rightClickedObject.geometry.boundingBox.max.y)) }}
                    </div>
                    <div class="right-clicked-popup-one-row">
                        <span>depth:</span>
                        {{ toGranularity(Math.abs(rightClickedObject.geometry.boundingBox.min.z) +  Math.abs(rightClickedObject.geometry.boundingBox.max.z)) }}
                    </div>
                </div>
            </div>
            <div class="right-clicked-object-actions-buttons">
                <button id="right-clicked-object-delete-button" @click="deleteOneBlock">Delete block</button>
            </div>
        </div>

        <ModalPopUp v-if="showModal">
            <div slot="header" class="modal-title">Help</div>
            <div slot="body">
                <div class="modal-import-from-file">
                    <p>Right now the game is in the prototype stage of development, so many things have not yet been implemented.</p>

                    <p>The goal of the game is to mine and recycle as much gold as possible while keeping as many aliens alive as possible.</p>

                    <p>The main gameplay is the creation and modification of two algorithms:</p>

                    <p><b>Algorithm 1</b>: the behavior of one individual (Alien) when completing a task.</p>

                    <p><b>Algorithm 2</b>: distribution of tasks between individuals (Aliens).</p>

                    <p>So far, changes in behavior are made only through code changes. But since each algorithm is in a separate function, it is quite easy to do this.</p>
                </div>
            </div>
            <div slot="footer" class="modal-import-from-file-footer">
                <button class="modal-default-button" @click="closePopUp">Close</button>
            </div>
        </ModalPopUp>

    </div>
</template>

<script>
import * as THREE from 'three';
import {eventBus, stationForGame} from '../main';
import {
        defaultColors,
        makeObjectPillar,
        makeObjectPlatform,
        makeObjectBox1,
        makeObjectBox2,
        makeObjectBox3,
        makeObjectFrame,
        makeObjectRowSpacer,
        makeObjectChannelBeam,
        makeObjectMetalDecking,
        makeObjectSafetyNet,
        makeObjectAlien,
        factoryTexture,
        goldMineTexture,
    } from '../objects';
import {
        randomArbitrary,
        calculateStationInfo,
        getObjectForStation,
        downloadFile,
        validateStationConstruction,
        capitalizeFirstLetter,
    } from '../utils';
import {
        applyPhysicalRestrictions,
        toGranularity,
        makeNextMove,
    } from '../objects_utils';
import { SuperIntelligence, Station } from '../logic';
import ModalPopUp from './ModalPopUp.vue';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { DragControls } from 'three/examples/jsm/controls/DragControls.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls.js';
// import { Interaction } from 'three.interaction';
// const loader = new THREE.TextureLoader();

export default {
    name: 'GameCanvas',
    components: {
        ModalPopUp,
    },
    data: function() {
        return {
            columnHeight: 0,
            time: {
                current: 0,
                previous: 0,
                delta: 0,
                tick: 0,
            },
            clock: null,
            camera: null,
            scene: null,
            renderer: null,
            canvas: null,
            objectPopUp: null,
            floor: null,
            raycaster: new THREE.Raycaster(),
            mouse: new THREE.Vector2(),
            constructionObjects: [],

            recreationZone: null,
            goldMineZone: null,
            oreFactoryZone: null,
            mainStorage: null,

            orbitControls: null,
            transformControl: null,
            pointerControls: null,
            isDragging: false,
            selectedObj: null,
            rightClickedObject: {
                name: '', id: '', position: {x: 0, y: 0, z: 0},
                geometry: {boundingBox: {min: {x: 0, y: 0, z: 0}, max: {x: 0, y: 0, z: 0}}}},
            playActions: [],
            playerFrequency: 0.01,
            alienObject: null,
            workerObject: null,
            moving: {
                forward: false,
                backward: false,
                left: false,
                right: false,
                prevTime: performance.now(),
                velocity: new THREE.Vector3(),
                direction: new THREE.Vector3(),
            },
            gamePaused: false,
            makeOneStep: false,
            superIntelligence: null,
            station: null,
            showModal: true,
        }
    },
    watch: {
        'rightClickedObject.position.x': function(newValue) {
            this.rightClickedObject.position.x = toGranularity(newValue);
        },
        'rightClickedObject.position.y': function(newValue) {
            this.rightClickedObject.position.y = toGranularity(newValue);
        },
        'rightClickedObject.position.z': function(newValue) {
            this.rightClickedObject.position.z = toGranularity(newValue);
        },
    },
    mounted() {
        window.addEventListener('resize', this.updateHeight);
        this.updateHeight().then(() => {
            this.init();
            this.animate();
        });
    },
    created: function() {
        eventBus.$on('addNewBlock', (blockName, positionX=null, positionY=null, positionZ=null, ) => {
            this.addNewObject(blockName, positionX, positionY, positionZ, true);
        });
        eventBus.$on('startGame', (numberOfAliens) => {
            this.autoBuildStation(stationForGame);

            this.makeZones();
            for(let i = 0; i < numberOfAliens; i++) {
                this.addNewObject('alien', 3+i, 0.16, 37, true);
            }
            this.superIntelligence = new SuperIntelligence(this.constructionObjects);
            this.station = new Station();
            this.station.numberOfAliens = numberOfAliens;
            eventBus.$emit('newStation', this.station);
        });
        eventBus.$on('pauseGame', (gamePaused) => {
            this.gamePaused = gamePaused;
        });
        eventBus.$on('makeOneStep', () => {
            this.makeOneStep = true;
        });
        // eventBus.$on('autoBuildStation', (instructions=exampleOfBuildStation) => {
        //     this.autoBuildStation(instructions);
        // });

        eventBus.$on('exportStationToFile', () => {
            const station = getObjectForStation(this.constructionObjects);
            downloadFile('station.json', JSON.stringify(station));
        });

        eventBus.$on('validateConstruction', () => {
            const status = validateStationConstruction(this.constructionObjects, this.floor);
            if (!status.isValid) {
                // highlight objects with errors/warnings
                status.problems.forEach(problem => {
                    problem.obj.material.color.setHex(0xff3333);
                });
            }
            eventBus.$emit('validateConstructionResult', status);
        });

        eventBus.$on('deleteAllBlocks', () => {
            this.deleteAllBlocks();
        });

        document.addEventListener('keydown', this.onDocumentKeyDown, false);
        document.addEventListener('keyup', this.onDocumentKeyUp, false);
    },
    methods: {
        updateHeight: function() {
            return new Promise((resolve)=> {
                this.columnHeight = document.documentElement.clientHeight - 96;
                resolve();
            });
        },
        capitalizeFirstLetter: function(string) {
            return capitalizeFirstLetter(string)
        },
        toGranularity: function(value) {
            return toGranularity(value);
        },
        init: function() {
            this.objectPopUp = document.getElementById('object-popup');
            this.canvas = document.getElementById('place-for-canvas');
            this.scene = new THREE.Scene();
            this.scene.background = new THREE.Color(0x3f3f3f);
            // this.scene.background = new THREE.Color(0xffffff);
            this.camera = new THREE.PerspectiveCamera(75, this.canvas.offsetWidth / this.canvas.offsetHeight, 0.1, 1000);
            this.camera.position.set(15, 20, 60);

            //  lights ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            const ambientLight = new THREE.AmbientLight(0xffffff, 0.7);
            this.scene.add(ambientLight);

            const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.2);
            this.scene.add(hemiLight);

            const spotLight = new THREE.SpotLight(0xffffff, 0.4);
            spotLight.position.set(40, 40, 50);
            spotLight.castShadow = true;
            spotLight.shadow.mapSize.width = 1024;
            spotLight.shadow.mapSize.height = 1024;
            spotLight.shadow.camera.near = 1;
            spotLight.shadow.camera.far = 100;
            spotLight.shadow.camera.fov = 45;
            this.scene.add(spotLight);

            // const light = new THREE.DirectionalLight(0xffffff, 0.01);
            // light.position.set(40,40,50);
            // light.castShadow = false;
            // light.shadow.camera.near = 0.1;
            // light.shadow.camera.far = 100;
            // // light.target.position.set(40, 10, 10);
            // // this.scene.add(light.target);
            // // light.shadowCameraVisible = true;
            // this.scene.add(light);

            // const helper = new THREE.CameraHelper(spotLight.shadow.camera);
            // this.scene.add(helper);

            // floor ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            const planeSize = 40;
            const loader = new THREE.TextureLoader();
            const texture = loader.load(require('../assets/textures/floor.png'));
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            texture.magFilter = THREE.NearestFilter;
            const repeats = planeSize / 2;
            texture.repeat.set(repeats, repeats);

            const planeGeo = new THREE.PlaneBufferGeometry(planeSize, planeSize);
            const planeMat = new THREE.MeshPhongMaterial({
                map: texture,
                side: THREE.DoubleSide,
            });
            this.floor = new THREE.Mesh(planeGeo, planeMat);
            this.floor.receiveShadow = true;
            this.floor.rotation.x = Math.PI * -.5;
            this.floor.position.x += 20;
            this.floor.position.z += 20;
            this.scene.add(this.floor);

            // Renderer ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            this.renderer = new THREE.WebGLRenderer({antialias: true});
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.setSize(this.canvas.offsetWidth, this.canvas.offsetHeight);
            this.renderer.shadowMap.enabled = true;
            this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
            this.canvas.appendChild(this.renderer.domElement);
            this.camera.updateMatrixWorld();

            // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

            const axesHelper = new THREE.AxesHelper(5);
            this.scene.add(axesHelper);

            this.generateStars();
            // this.loadAlien();

            this.clock = new THREE.Clock();
            this.clock.start();

            // PointerLockControls ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            this.pointerControls = new PointerLockControls(this.camera, this.renderer.domElement);

            // OrbitControls ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);
            this.orbitControls.zoomSpeed = 0.1;
            this.orbitControls.minDistance = 1;
            this.orbitControls.maxDistance = 100;
            this.orbitControls.target.set(20, 0, 20);
            this.camera.lookAt(new THREE.Vector3(15,0,20));

            // TransformControls ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            this.transformControl = new TransformControls(this.camera, this.renderer.domElement);
            this.transformControl.addEventListener('change', () => {
                applyPhysicalRestrictions(this.selectedObj, this.constructionObjects);
                this.render;
            });
            this.transformControl.addEventListener('dragging-changed', (event) => {
                this.orbitControls.enabled = ! event.value;
            } );
            this.transformControl.setMode('translate');
            this.scene.add(this.transformControl);

            // DragControls ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            const dragControls = new DragControls(
                this.constructionObjects, this.camera, this.renderer.domElement
            );

            dragControls.addEventListener('drag', (event) => {
                applyPhysicalRestrictions(event.object, this.constructionObjects);
            });

            dragControls.addEventListener('dragstart', () => {
                this.isDragging = true;
                this.orbitControls.enabled = false;
            });

            dragControls.addEventListener('dragend', () => {
                this.isDragging = false;
                this.orbitControls.enabled = true;
            });
            // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

            // this.interaction = new Interaction(this.renderer, this.scene, this.camera);

            this.canvas.addEventListener('mousemove', this.onCanvasMouseMove, false);
            this.canvas.addEventListener('click', this.onCanvasMouseClick, false);
            this.canvas.addEventListener('contextmenu', this.onCanvasMouseRightClick, false);
        },
        animate: function() {

            if (!this.gamePaused || this.makeOneStep) {
                this.time.delta += this.clock.getDelta();
                if (this.time.delta > this.playerFrequency) {
                    this.time.delta = 0;
                    this.playNextAction();
                    this.makeOneStep = false;
                }

                this.checkAndSetGoals();
                if (this.station) this.station.oreFactoryProduceGold();
            }

            // Moving camera by keys ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            if (this.moving.forward || this.moving.backward || this.moving.left || this.moving.right) {

                this.moving.velocity.x -= this.moving.velocity.x * 10.0 * this.time.delta;
				this.moving.velocity.z -= this.moving.velocity.z * 10.0 * this.time.delta;

                this.moving.direction.z = Number(this.moving.forward) - Number(this.moving.backward);
                this.moving.direction.x = Number(this.moving.right) - Number(this.moving.left);
                this.moving.direction.normalize();

                const vec = new THREE.Vector3();

                if (this.moving.forward || this.moving.backward) {
                    this.moving.velocity.z -= this.moving.direction.z * 40.0 * this.time.delta;
                    vec.setFromMatrixColumn(this.camera.matrix, 0);
                    vec.crossVectors(this.camera.up, vec);
                    this.camera.position.addScaledVector(vec, - this.moving.velocity.z * this.time.delta);
                    this.orbitControls.target.addScaledVector(vec, - this.moving.velocity.z * this.time.delta);
                }
                if (this.moving.left || this.moving.right) {
                    this.moving.velocity.x -= this.moving.direction.x * 40.0 * this.time.delta;
                    vec.setFromMatrixColumn(this.camera.matrix, 0);
                    this.camera.position.addScaledVector(vec, - this.moving.velocity.x * this.time.delta );
                    this.orbitControls.target.addScaledVector(vec, - this.moving.velocity.x * this.time.delta);
                }

                this.orbitControls.update();
                this.camera.updateProjectionMatrix();
            }
            // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

            requestAnimationFrame(this.animate);
            this.render();
        },
        render: function() {
            this.renderer.render(this.scene, this.camera);
        },
        addNewObject: function(blockName, positionX=null, positionY=null, positionZ=null, applyRestrictions=false) {
            let actionFunction = null;
            if (!positionX) positionX = 20;
            if (!positionY) positionY = 3;
            if (!positionZ) positionZ = 32;

            switch (blockName) {
            case 'pillar':
                actionFunction = makeObjectPillar;
                break;
            case 'platform':
                actionFunction = makeObjectPlatform;
                break;
            case 'box1':
                actionFunction = makeObjectBox1;
                break;
            case 'box2':
                actionFunction = makeObjectBox2;
                break;
            case 'box3':
                actionFunction = makeObjectBox3;
                break;
            case 'frame':
                actionFunction = makeObjectFrame;
                break;
            case 'spacer':
                actionFunction = makeObjectRowSpacer;
                break;
            case 'beam':
                actionFunction = makeObjectChannelBeam;
                break;
            case 'decking':
                actionFunction = makeObjectMetalDecking;
                break;
            case 'net':
                actionFunction = makeObjectSafetyNet;
                break;
            case 'worker':
                actionFunction = this.loadWorker;
                break;
            case 'alien':
                actionFunction = makeObjectAlien;
                applyRestrictions = false;
                break;
            default:
                console.log('Error.Wrong construction block:', blockName);
            }

            if (actionFunction) {
                actionFunction(
                    positionX,
                    positionY,
                    positionZ,
                ).then(result => {
                    if (blockName != 'worker') {
                        if (applyRestrictions) applyPhysicalRestrictions(result, this.constructionObjects);
                        this.constructionObjects.push(result);
                    }
                    this.scene.add(result);
                });
            }
        },
        onCanvasMouseMove: function(event) {
            event.preventDefault();
            this.mouse.x = ( (event.clientX - this.renderer.domElement.offsetLeft) / this.renderer.domElement.width * window.devicePixelRatio ) * 2 - 1;
            this.mouse.y = -( (event.clientY - this.renderer.domElement.offsetTop) / this.renderer.domElement.height * window.devicePixelRatio ) * 2 + 1;

            // find intersections for highlight objects ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            this.raycaster.setFromCamera(this.mouse, this.camera);
            const intersects = this.raycaster.intersectObjects(this.constructionObjects);
            let intersectsObject = null;
            if ((intersects.length > 0) && !this.isDragging) {
                intersectsObject = intersects[0].object;
                if (intersectsObject.material.color.getHex() != 0xff3333) {
                    // only if not selected as object with error/warning
                    intersectsObject.material.color.setHex(0x99ff99);
                }
            }
            this.constructionObjects.forEach(obj => {
                    if (obj != this.selectedObj && obj != intersectsObject) {
                        if (obj.material.color.getHex() != 0xff3333) {
                            // only if not selected as object with error/warning
                            obj.material.color.setHex(defaultColors[obj.name]);
                        }
                    }
            });
            // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        },
        onCanvasMouseClick: function() {
            this.raycaster.setFromCamera(this.mouse, this.camera);
            const aliens = this.constructionObjects.filter(obj => obj.name == 'alien');

            const intersects = this.raycaster.intersectObjects(aliens);
            if ((intersects.length > 0) && !this.isDragging) {
                intersects[0].object.material.color.setHex(0x99ff99);
                if (this.selectedObj) {
                    this.selectedObj.material.color.setHex(defaultColors[this.selectedObj.name]); // deselect old object
                }
                this.selectedObj = intersects[0].object;
                this.transformControl.attach(this.selectedObj);
                eventBus.$emit('selectingObject', this.selectedObj);
            } else {
                if (this.transformControl) {
                    this.transformControl.detach();
                }
                aliens.forEach(obj => {
                    if (obj.material.color.getHex() != 0xff3333) {
                        // only if not selected as object with error/warning
                        obj.material.color.setHex(defaultColors[obj.name]);
                    }
                });
                this.selectedObj = null;
                eventBus.$emit('unSelectingObject');
            }
        },
        onCanvasMouseRightClick: function(event) {
            this.raycaster.setFromCamera(this.mouse, this.camera);
            const intersects = this.raycaster.intersectObjects(this.constructionObjects);
            if ((intersects.length > 0) && !this.isDragging) {
                this.rightClickedObject = intersects[0].object;
                this.objectPopUp.style.top = event.clientY + 'px';
                this.objectPopUp.style.left = (event.clientX + 20) + 'px';
                this.objectPopUp.style.display = 'block';
            }
        },
        closeObjectPopUp: function() {
            console.log('call closeObjectPopUp');
            this.objectPopUp.style.display = 'none';
        },
        closePopUp: function() {
            this.showModal = false;
        },
        addTransformControls: function() {
            this.transformControl = new TransformControls(this.camera, this.renderer.domElement);
            this.transformControl.addEventListener('change', this.render);
            this.transformControl.addEventListener('dragging-changed', (event) => {
                this.orbitControls.enabled = ! event.value;
            } );
            this.transformControl.setMode('translate');
            this.scene.add(this.transformControl);
        },
        makeZones: function() {
            // recreation zone ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            const geometry = new THREE.BoxGeometry(6, 0.05, 4);
            const material = new THREE.MeshLambertMaterial({
                color: 0xff3333,
                // map: box2Texture,
            });
            const mesh = new THREE.Mesh(geometry, material);
            mesh.geometry.computeBoundingBox();
            mesh.geometry.computeBoundingSphere();
            mesh.castShadow = true;
            mesh.receiveShadow = true;
            mesh.position.set(3, 0.025, 38);
            this.recreationZone = mesh;
            this.scene.add(mesh);

            // gold mine zone ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            const geometry2 = new THREE.BoxGeometry(8, 0.05, 6);
            const material2 = new THREE.MeshLambertMaterial({
                color: 0xe5c100,
                // map: box2Texture,
            });
            const mesh2 = new THREE.Mesh(geometry2, material2);
            mesh2.geometry.computeBoundingBox();
            mesh2.geometry.computeBoundingSphere();
            // mesh2.castShadow = true;
            mesh2.receiveShadow = true;
            mesh2.position.set(36, 0.025, 37);
            this.goldMineZone = mesh2;
            this.scene.add(mesh2);

            // building of Mine
            const geometry6 = new THREE.BoxGeometry(6, 1, 4);
            const material6 = new THREE.MeshLambertMaterial({
                map: goldMineTexture,
            });
            const mesh6 = new THREE.Mesh(geometry6, material6);
            mesh6.geometry.computeBoundingBox();
            mesh6.geometry.computeBoundingSphere();
            mesh6.castShadow = true;
            mesh6.receiveShadow = true;
            mesh6.position.set(36, 0.5, 37);
            this.oreFactoryZone = mesh6;
            this.scene.add(mesh6);

            // Ore Factory zone ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            const geometry3 = new THREE.BoxGeometry(8, 0.05, 6);
            const material3 = new THREE.MeshLambertMaterial({
                color: 0x0578a6d,
                // map: box2Texture,
            });
            const mesh3 = new THREE.Mesh(geometry3, material3);
            mesh3.geometry.computeBoundingBox();
            mesh3.geometry.computeBoundingSphere();
            // mesh3.castShadow = true;
            mesh3.receiveShadow = true;
            mesh3.position.set(36, 0.025, 3);
            this.oreFactoryZone = mesh3;
            this.scene.add(mesh3);

            // building of Factory
            const geometry5 = new THREE.BoxGeometry(6, 3, 4);
            const material5 = new THREE.MeshLambertMaterial({
                // color: 0x0c164f,
                map: factoryTexture,
            });
            const mesh5 = new THREE.Mesh(geometry5, material5);
            mesh5.geometry.computeBoundingBox();
            mesh5.geometry.computeBoundingSphere();
            mesh5.castShadow = true;
            mesh5.receiveShadow = true;
            mesh5.position.set(36, 1.5, 3);
            this.oreFactoryZone = mesh5;
            this.scene.add(mesh5);

            // Main Storage ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            const geometry4 = new THREE.BoxGeometry(8, 0.05, 12);
            const material4 = new THREE.MeshLambertMaterial({
                color: 0xb57124,
                // map: platformTexture,
            });
            const mesh4 = new THREE.Mesh(geometry4, material4);
            mesh4.geometry.computeBoundingBox();
            mesh4.geometry.computeBoundingSphere();
            // mesh4.castShadow = true;
            mesh4.receiveShadow = true;
            mesh4.position.set(6, 0.025, 18);
            this.mainStorage = mesh4;
            this.scene.add(mesh4);

        },
        onDocumentKeyDown: function(event) {
            switch (event.keyCode) {
            case 17:  // Ctrl
                this.orbitControls.enabled = false;
                break;
            case 87:  // w
                this.moving.forward = true;
                break;
            case 83:  // s
                this.moving.backward = true;
                break;
            case 68:  // d
                this.moving.right = true;
                break;
            case 65:  // a
                this.moving.left = true;
                break;
            default:
                console.log('down event keyCode:', event.keyCode);
            }
        },
        onDocumentKeyUp: function(event) {
            switch (event.keyCode) {
            case 17:  // Ctrl
                this.orbitControls.enabled = true;
                break;
            case 87:  // w
                this.moving.forward = false;
                break;
            case 83:  // s
                this.moving.backward = false;
                break;
            case 68:  // d
                this.moving.right = false;
                break;
            case 65:  // a
                this.moving.left = false;
                break;
            default:
                console.log('up event keyCode:', event.keyCode);
            }
        },
        generateStars: function() {
            let geometry = new THREE.BufferGeometry();
            let material = new THREE.PointsMaterial({color: 0xffffff});
            let vertices = [];
            let s_c;

            for (let i = 1; i < 2000; i++) {
                s_c = new THREE.Spherical(
                    randomArbitrary(200, 900),
                    2 * Math.PI * Math.random(),
                    2 * Math.PI * Math.random()
                );
                vertices.push(
                    s_c.radius * Math.sin(s_c.theta) * Math.cos(s_c.phi),   // x
                    s_c.radius * Math.sin(s_c.theta) * Math.sin(s_c.phi),   // y
                    s_c.radius * Math.cos(s_c.theta)                        // z
                );
            }
            geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
            const particles = new THREE.Points(geometry, material);
			this.scene.add(particles);
        },
        deleteOneBlock: function() {
            if (this.rightClickedObject) {
                if (this.rightClickedObject == this.selectedObj) {
                    if (this.transformControl) {
                        this.transformControl.detach();
                    }
                    this.selectedObj = null;
                    eventBus.$emit('unSelectingObject');
                }
                this.rightClickedObject.geometry.dispose();
                this.rightClickedObject.material.dispose();
                this.scene.remove(this.rightClickedObject);

                const index = this.constructionObjects.indexOf(this.rightClickedObject);
                if (index > -1) {
                    this.constructionObjects.splice(index, 1);
                }
                const stationInfo = calculateStationInfo(this.constructionObjects);
                eventBus.$emit('newStationInfo', stationInfo);
                this.closeObjectPopUp();
            }
        },
        deleteAllBlocks: function() {
            this.transformControl.detach();
            this.constructionObjects.forEach(obj => {
                obj.geometry.dispose();
                obj.material.dispose();
                this.scene.remove(obj);
            });
            if (this.workerObject) {
                this.scene.remove(this.workerObject);
                this.workerObject = null;
            }
            this.constructionObjects.length = 0;  // clear list, but stay with same object for (DragControl)
            const stationInfo = calculateStationInfo(this.constructionObjects);
            eventBus.$emit('newStationInfo', stationInfo);
        },
        autoBuildStation: function(instructions) {
            this.deleteAllBlocks();
            this.playActions = instructions.slice().reverse();
        },
        playNextAction: function() {

            const aliens = this.constructionObjects.filter(obj => obj.name == 'alien');

            if (aliens.length > 0) {
                for(const obj of aliens) {
                    makeNextMove(obj, aliens, this.station);
                }
            }

            if (this.playActions.length == 0) return;
            const currentAction = this.playActions.pop();

            this.addNewObject(
                currentAction.name,
                currentAction.position.x,
                currentAction.position.y,
                currentAction.position.z
            );
        },

        checkAndSetGoals: function() {
            for(const obj of this.constructionObjects) {
                if (obj.name == 'alien' && obj.goals.length == 0) {
                    const goals = this.superIntelligence.getNewGoals();
                    obj.goals = goals;
                    // console.log(obj.goals);
                }
            }
        },
    },
}
</script>


<style>
.canvas {
    background-color: #7649fe;
}
canvas:focus {
    outline: none;
}
#place-for-canvas {
    width: 100%;
    height: 100%;
    background-color: aqua;
    display: block;
}
#object-popup {
    display: none;
    position: absolute;
    background-color: #ccc;
    height: 155px;
    width: 185px;
    box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.75);
    border-radius: 4px;
    font-size: 80%;
}
.object-popup-title {
    color: #eee;
    background-color: #3f3f3f;
    width: 100%;
    text-shadow: 1px 1px 1px #000;
    border-radius: 4px 4px 0px 0px;
    position: static !important;
}
#object-popup-close {
    float: right;
    cursor: pointer;
    padding: 0px 5px;
    border-radius: 0px 4px 0px 0px;
    margin-top: -18px;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
}
#object-popup-close:hover {
    background-color: #555;
}
#object-popup .position-inputs-title {
    margin-top: 2px;
    text-align: left;
    margin-left: 4px;
    font-weight: bold;
}
#object-popup .position-inputs-title-right {
    margin-top: 2px;
    text-align: left;
    font-weight: bold;
}
#object-popup .position-inputs {
    text-align: left;
    padding: 5px;
}
#object-popup .position-inputs div {
    margin-bottom: 3px;
}
#object-popup .position-info-title {
    display: inline-block;
    width: 11px;
}
#object-popup .position-inputs input {
    width: 53px;
}
.right-clicked-object-info-left-column,
.right-clicked-object-info-right-column {
    display: inline-block;
    vertical-align: top;
}
.right-clicked-object-info-left-column {
    width: 85px;
    margin-right: 15px;
}
.right-clicked-object-info-right-column {
    text-align: left;
    width: 80px;
    /* background-color: #7649fe; */
}
.right-clicked-popup-one-row span {
    display: inline-block;
    width: 43px;
    /* text-align: right; */
}
.right-clicked-object-actions-buttons {
    margin-top: 3px;
}
#right-clicked-object-delete-button {
    background-color: #ffafaf;
    border-color: #ffb6b6;
    border-radius: 3px;
}
.modal-container {
    width: 450px !important;
}
.modal-body {
    text-align: left;
}
</style>
