<template>
    <div id="container">
        <!-- <GameLeftColumn></GameLeftColumn> -->
        <GameCanvas></GameCanvas>
        <GameRightColumn></GameRightColumn>

        <ModalPopUp v-if="showModal" @close="showModal = false">
            <div slot="header" class="modal-title">{{ modalHeader }}</div>
            <div slot="body" v-html="modalBody"></div>
        </ModalPopUp>
    </div>
</template>

<script>
import {eventBus} from '../main';

// import GameLeftColumn from '../components/GameLeftColumn.vue';
import GameCanvas from '../components/GameCanvas.vue';
import GameRightColumn from '../components/GameRightColumn.vue';
import ModalPopUp from '../components/ModalPopUp.vue';

export default {
    name: 'Game',
    components: {
        // GameLeftColumn,
        GameCanvas,
        GameRightColumn,
        ModalPopUp,
    },
    data: function() {
        return {
            showModal: false,
            modalHeader: '',
            modalBody: '',
        }
    },
    created: function() {
        eventBus.$on('showPopUp', (info) => {
            this.modalHeader = info.header;
            this.modalBody = info.body;
            this.showModal = true;
        });

    },
}
</script>

<style>
#container {
    display: flex;
    justify-content: space-between;
}
.left-column,
.right-column {
  flex: 0 0 240px;
}
.canvas {
    flex: 1;
}
.modal-title {
    font-size: 120%;
    font-weight: bold;
}
.modal-construction-status-ok {
    color: #4db74d;
}

.modal-construction-status-error {
    color: #b74d4d;
    margin-bottom: 20px;
}
.modal-construction-status-one-problem {
    text-align: left;
    margin: 11px 0;
    padding-left: 5px;
    font-size: 85%;
}
.modal-construction-status-one-problem.critical {
    border-left: solid 3px #b74d4d;
}
.modal-construction-status-one-problem.warning {
    border-left: solid 3px #ffd065;
}
</style>
