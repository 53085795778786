<template>
    <div id="app">
        <Header></Header>
        <router-view></router-view>
    </div>
</template>

<script>
import Header from './components/Header.vue';


export default {
    name: 'App',
    components: {
        Header,
    },
    data: function() {
        return {

        }
    },
}
</script>

<style>
body {
    margin: 0;
    background: #3f3f3f;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 100vh;
}
#container {
    display: flex;
    justify-content: space-between;
}
</style>
