import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';

import { makeNextMove } from './objects_utils';
import { alienFullHealthLevel } from './logic';

export const defaultColors = {
    pillar: 0xffd1a3,   // 0xCC7F29,
    platform: 0xd4ecff, // 0x79a4d5,
    box1: 0xdbd1c8,     // 0x5d4519,
    box2: 0x757575,     // 0x4b2e77,
    box3: 0xdbd1c8,
    frame: 0x9999dd,
    spacer: 0xffd1a3,
    beam: 0xfffc99,
    decking: 0xcccccc,
    net: 0xcccccc,
    alien: 0x33ff33,
}

export const snapDistance = 0.5;
export const moveStep = 0.1;
const loader = new THREE.TextureLoader();


// Pillar texture ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const pillarTexture = loader.load(require('./assets/textures/pillar.jpg'));
pillarTexture.wrapS = THREE.RepeatWrapping;
pillarTexture.wrapT = THREE.RepeatWrapping;
pillarTexture.magFilter = THREE.NearestFilter;
pillarTexture.repeat.set(0.1, 1);

// Platform texture ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const platformTexture = loader.load(require('./assets/textures/platform.jpg'));
platformTexture.wrapS = THREE.RepeatWrapping;
platformTexture.wrapT = THREE.RepeatWrapping;
platformTexture.magFilter = THREE.NearestFilter;
platformTexture.repeat.set(1, 2);

// Boxes textures ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const boxTexture = loader.load(require('./assets/textures/box.jpg'));
const box2Texture = loader.load(require('./assets/textures/box2.jpg'));

// Platform 2 texture ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const platform2Texture = loader.load(require('./assets/textures/platform_2.jpg'));
platform2Texture.wrapS = THREE.RepeatWrapping;
platform2Texture.wrapT = THREE.RepeatWrapping;
platform2Texture.magFilter = THREE.NearestFilter;
platform2Texture.repeat.set(3, 3);

// Factory texture ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const factoryTexture = loader.load(require('./assets/textures/factory.jpg'));
factoryTexture.wrapS = THREE.RepeatWrapping;
factoryTexture.wrapT = THREE.RepeatWrapping;
factoryTexture.magFilter = THREE.NearestFilter;
factoryTexture.repeat.set(1, 1);

// Gold Mine texture ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const goldMineTexture = loader.load(require('./assets/textures/gold_mine.jpg'));
goldMineTexture.wrapS = THREE.RepeatWrapping;
goldMineTexture.wrapT = THREE.RepeatWrapping;
goldMineTexture.magFilter = THREE.NearestFilter;
goldMineTexture.repeat.set(1, 1);


export function makeObjectPillar(x, y, z) {
    return new Promise((resolve) => {
        const geometry = new THREE.BoxGeometry(0.4, 4, 0.4);
        const material = new THREE.MeshLambertMaterial({
            color: defaultColors['pillar'],
            map: pillarTexture,
        });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.geometry.computeBoundingBox();
        mesh.geometry.computeBoundingSphere();
        mesh.castShadow = true;
        mesh.receiveShadow = true;
        mesh.position.set(x, y, z);
        mesh.name = 'pillar';
        mesh.weight = 8;
        mesh.cost = 13;
        mesh.time = 2;
        mesh.spots = [
            {id: 1, snap_distance: snapDistance, type: 'type1', position: new THREE.Vector3(-0.1, 2, -0.1)},
            {id: 2, snap_distance: snapDistance, type: 'type1', position: new THREE.Vector3(0.1, 2, -0.1)},
            {id: 3, snap_distance: snapDistance, type: 'type1', position: new THREE.Vector3(0.1, 2, 0.1)},
            {id: 4, snap_distance: snapDistance, type: 'type1', position: new THREE.Vector3(-0.1, 2, 0.1)},

            {id: 5, snap_distance: snapDistance, type: 'type4', position: new THREE.Vector3(0, 2, 0)},
            {id: 6, snap_distance: snapDistance, type: 'type4', position: new THREE.Vector3(0, -2, 0)},
        ];
        resolve(mesh);
    });
}


export function makeObjectPlatform(x, y, z) {
    return new Promise((resolve) => {
        const geometry = new THREE.BoxGeometry(2, 0.2, 4);
        const material = new THREE.MeshLambertMaterial({
            color: defaultColors['platform'],
            map: platformTexture,
        });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.geometry.computeBoundingBox();
        mesh.geometry.computeBoundingSphere();
        mesh.castShadow = true;
        mesh.receiveShadow = true;
        mesh.position.set(x, y, z);
        mesh.name = 'platform';
        mesh.weight = 12;
        mesh.cost = 35;
        mesh.time = 3;
        mesh.spots = [
            {id: 1, snap_distance: snapDistance, type: 'type1', position: new THREE.Vector3(-0.9, -0.1, -1.9)},
            {id: 2, snap_distance: snapDistance, type: 'type1', position: new THREE.Vector3(0.9, -0.1, -1.9)},
            {id: 3, snap_distance: snapDistance, type: 'type1', position: new THREE.Vector3(0.9, -0.1, 1.9)},
            {id: 4, snap_distance: snapDistance, type: 'type1', position: new THREE.Vector3(-0.9, -0.1, 1.9)},

            {id: 5, snap_distance: snapDistance, type: 'type2', position: new THREE.Vector3(0, 0, 2)},
            {id: 6, snap_distance: snapDistance, type: 'type2', position: new THREE.Vector3(0, 0, -2)},

            {id: 7, snap_distance: snapDistance, type: 'type3', position: new THREE.Vector3(-1, 0, 0)},
            {id: 8, snap_distance: snapDistance, type: 'type3', position: new THREE.Vector3(1, 0, 0)},
        ];
        resolve(mesh);
    });
}


export function makeObjectBox1(x, y, z) {
    return new Promise((resolve) => {
        const geometry = new THREE.BoxGeometry(1, 1, 1);
        const material = new THREE.MeshLambertMaterial({
            color: defaultColors['box1'],
            map: boxTexture,
        });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.geometry.computeBoundingBox();
        mesh.geometry.computeBoundingSphere();
        mesh.castShadow = true;
        mesh.receiveShadow = true;
        mesh.position.set(x, y, z);
        mesh.name = 'box1';
        mesh.weight = 2;
        mesh.cost = 7;
        mesh.time = 0.5;
        mesh.spots = [
            {id: 1, snap_distance: snapDistance, type: 'type5', position: new THREE.Vector3(0, 0.5, 0)},
            {id: 2, snap_distance: snapDistance, type: 'type5', position: new THREE.Vector3(0, -0.5, 0)},
            {id: 3, snap_distance: snapDistance, type: 'type5', position: new THREE.Vector3(0.5, 0, 0)},
            {id: 4, snap_distance: snapDistance, type: 'type5', position: new THREE.Vector3(-0.5, 0, 0)},
            {id: 5, snap_distance: snapDistance, type: 'type5', position: new THREE.Vector3(0, 0, 0.5)},
            {id: 6, snap_distance: snapDistance, type: 'type5', position: new THREE.Vector3(0, 0, -0.5)},
        ];
        resolve(mesh);
    });
}


export function makeObjectBox2(x, y, z) {
    return new Promise((resolve) => {
        const geometry = new THREE.BoxGeometry(1, 1, 2);
        const material = new THREE.MeshLambertMaterial({
            color: defaultColors['box2'],
            map: boxTexture,
        });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.geometry.computeBoundingBox();
        mesh.geometry.computeBoundingSphere();
        mesh.castShadow = true;
        mesh.receiveShadow = true;
        mesh.position.set(x, y, z);
        mesh.name = 'box2';
        mesh.weight = 4;
        mesh.cost = 14;
        mesh.time = 1;
        mesh.spots = [
            {id: 1, snap_distance: snapDistance, type: 'type5', position: new THREE.Vector3(0, 0.5, 0.5)},
            {id: 2, snap_distance: snapDistance, type: 'type5', position: new THREE.Vector3(0, -0.5, 0.5)},
            {id: 3, snap_distance: snapDistance, type: 'type5', position: new THREE.Vector3(0.5, 0, 0.5)},
            {id: 4, snap_distance: snapDistance, type: 'type5', position: new THREE.Vector3(-0.5, 0, 0.5)},
            {id: 5, snap_distance: snapDistance, type: 'type5', position: new THREE.Vector3(0, 0.5, -0.5)},
            {id: 6, snap_distance: snapDistance, type: 'type5', position: new THREE.Vector3(0, -0.5, -0.5)},
            {id: 7, snap_distance: snapDistance, type: 'type5', position: new THREE.Vector3(0.5, 0, -0.5)},
            {id: 8, snap_distance: snapDistance, type: 'type5', position: new THREE.Vector3(-0.5, 0, -0.5)},
            {id: 9, snap_distance: snapDistance, type: 'type5', position: new THREE.Vector3(0, 0, 1)},
            {id: 10, snap_distance: snapDistance, type: 'type5', position: new THREE.Vector3(0, 0, -1)},
        ];
        resolve(mesh);
    });
}


export function makeObjectFrame(x, y, z) {
    return new Promise((resolve) => {
        new MTLLoader().load(require('@/assets/models/rack_frame.mtl'), (materials) => {
            materials.preload();
            new OBJLoader().setMaterials(materials).load(require('@/assets/models/rack_frame.obj'), (obj) => {
                const mesh = obj.children[0];

                const material = new THREE.MeshLambertMaterial({
                    color: defaultColors['frame'],
                    map: pillarTexture,
                });
                mesh.material = material;

                mesh.geometry.computeBoundingBox();
                mesh.geometry.computeBoundingSphere();
                mesh.castShadow = true;
                mesh.receiveShadow = true;
                mesh.position.set(x, y, z);
                mesh.name = 'frame';
                mesh.weight = 105;  // kg
                mesh.cost = 400;    // $
                mesh.time = 2;
                mesh.spots = [
                    // for Spacer
                    {id: 1, snap_distance: snapDistance, type: 'type6', position: new THREE.Vector3(0.6, 1.8, 0)},
                    {id: 2, snap_distance: snapDistance, type: 'type6', position: new THREE.Vector3(-0.6, 1.8, 0)},
                    {id: 3, snap_distance: snapDistance, type: 'type6', position: new THREE.Vector3(0.6, 0.6, 0)},
                    {id: 4, snap_distance: snapDistance, type: 'type6', position: new THREE.Vector3(-0.6, 0.6, 0)},
                    {id: 5, snap_distance: snapDistance, type: 'type6', position: new THREE.Vector3(0.6, -0.6, 0)},
                    {id: 6, snap_distance: snapDistance, type: 'type6', position: new THREE.Vector3(-0.6, -0.6, 0)},
                    {id: 7, snap_distance: snapDistance, type: 'type6', position: new THREE.Vector3(0.6, -1.8, 0)},
                    {id: 8, snap_distance: snapDistance, type: 'type6', position: new THREE.Vector3(-0.6, -1.8, 0)},
                    // for Beam
                    {id: 9, snap_distance: snapDistance, type: 'type7', position: new THREE.Vector3(0.55, 1.8, 0.05)},
                    {id: 10, snap_distance: snapDistance, type: 'type7', position: new THREE.Vector3(0.55, 1.8, -0.05)},
                    {id: 11, snap_distance: snapDistance, type: 'type7', position: new THREE.Vector3(0.55, 0.6, 0.05)},
                    {id: 12, snap_distance: snapDistance, type: 'type7', position: new THREE.Vector3(0.55, 0.6, -0.05)},
                    {id: 13, snap_distance: snapDistance, type: 'type7', position: new THREE.Vector3(0.55, -0.6, 0.05)},
                    {id: 14, snap_distance: snapDistance, type: 'type7', position: new THREE.Vector3(0.55, -0.6, -0.05)},
                    {id: 15, snap_distance: snapDistance, type: 'type7', position: new THREE.Vector3(0.55, -1.8, 0.05)},
                    {id: 16, snap_distance: snapDistance, type: 'type7', position: new THREE.Vector3(0.55, -1.8, -0.05)},

                    {id: 17, snap_distance: snapDistance, type: 'type7', position: new THREE.Vector3(-0.55, 1.8, 0.05)},
                    {id: 18, snap_distance: snapDistance, type: 'type7', position: new THREE.Vector3(-0.55, 1.8, -0.05)},
                    {id: 19, snap_distance: snapDistance, type: 'type7', position: new THREE.Vector3(-0.55, 0.6, 0.05)},
                    {id: 20, snap_distance: snapDistance, type: 'type7', position: new THREE.Vector3(-0.55, 0.6, -0.05)},
                    {id: 21, snap_distance: snapDistance, type: 'type7', position: new THREE.Vector3(-0.55, -0.6, 0.05)},
                    {id: 22, snap_distance: snapDistance, type: 'type7', position: new THREE.Vector3(-0.55, -0.6, -0.05)},
                    {id: 23, snap_distance: snapDistance, type: 'type7', position: new THREE.Vector3(-0.55, -1.8, 0.05)},
                    {id: 24, snap_distance: snapDistance, type: 'type7', position: new THREE.Vector3(-0.55, -1.8, -0.05)},

                ];
                resolve(mesh);
            });
        });
    });
}


export function makeObjectRowSpacer(x, y, z) {
    return new Promise((resolve) => {
        new MTLLoader().load(require('@/assets/models/row_spacer.mtl'), (materials) => {
            materials.preload();
            new OBJLoader().setMaterials(materials).load(require('@/assets/models/row_spacer.obj'), (obj) => {
                const mesh = obj.children[0];

                const material = new THREE.MeshLambertMaterial({
                    color: defaultColors['spacer'],
                    map: pillarTexture,
                });
                mesh.material = material;

                mesh.geometry.computeBoundingBox();
                mesh.geometry.computeBoundingSphere();
                mesh.castShadow = true;
                mesh.receiveShadow = true;
                mesh.position.set(x, y, z);
                mesh.name = 'spacer';
                mesh.weight = 7;  // kg
                mesh.cost = 15;    // $
                mesh.time = 0.2;
                mesh.spots = [
                    {id: 1, snap_distance: snapDistance, type: 'type6', position: new THREE.Vector3(0.4, 0, 0)},
                    {id: 2, snap_distance: snapDistance, type: 'type6', position: new THREE.Vector3(-0.4, 0, 0)},
                ];
                resolve(mesh);
            });
        });
    });
}


export function makeObjectChannelBeam(x, y, z) {
    return new Promise((resolve) => {
        new MTLLoader().load(require('@/assets/models/channel_beam.mtl'), (materials) => {
            materials.preload();
            new OBJLoader().setMaterials(materials).load(require('@/assets/models/channel_beam.obj'), (obj) => {
                const mesh = obj.children[0];

                const material = new THREE.MeshLambertMaterial({
                    color: defaultColors['beam'],
                    map: pillarTexture,
                });
                mesh.material = material;

                mesh.geometry.computeBoundingBox();
                mesh.geometry.computeBoundingSphere();
                mesh.castShadow = true;
                mesh.receiveShadow = true;
                mesh.position.set(x, y, z);
                mesh.name = 'beam';
                mesh.weight = 21;  // kg
                mesh.cost = 132;    // $
                mesh.time = 0.5;
                mesh.spots = [
                    {id: 1, snap_distance: snapDistance, type: 'type7', position: new THREE.Vector3(0, 0, 2)},
                    {id: 2, snap_distance: snapDistance, type: 'type7', position: new THREE.Vector3(0, 0, -2)},
                    {id: 3, snap_distance: snapDistance, type: 'type8', position: new THREE.Vector3(0, 0.05, 0)},
                ];
                resolve(mesh);
            });
        });
    });
}


export function makeObjectMetalDecking(x, y, z) {
    return new Promise((resolve) => {
        new MTLLoader().load(require('@/assets/models/metal_decking.mtl'), (materials) => {
            materials.preload();
            new OBJLoader().setMaterials(materials).load(require('@/assets/models/metal_decking.obj'), (obj) => {
                const mesh = obj.children[0];

                const material = new THREE.MeshLambertMaterial({
                    color: defaultColors['decking'],
                    map: platform2Texture,
                });
                mesh.material = material;

                mesh.geometry.computeBoundingBox();
                mesh.geometry.computeBoundingSphere();
                mesh.castShadow = true;
                mesh.receiveShadow = true;
                mesh.position.set(x, y, z);
                mesh.name = 'decking';
                mesh.weight = 45;  // kg
                mesh.cost = 230;   // $
                mesh.time = 0.5;
                mesh.spots = [
                    {id: 1, snap_distance: snapDistance, type: 'type8', position: new THREE.Vector3(0.55, -0.05, 0)},
                    {id: 2, snap_distance: snapDistance, type: 'type8', position: new THREE.Vector3(-0.55, -0.05, 0)},
                ];
                resolve(mesh);
            });
        });
    });
}


export function makeObjectBox3(x, y, z) {
    return new Promise((resolve) => {
        const geometry = new THREE.BoxGeometry(0.5, 0.5, 0.5);
        const material = new THREE.MeshLambertMaterial({
            color: defaultColors['box3'],
            map: box2Texture,
        });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.geometry.computeBoundingBox();
        mesh.geometry.computeBoundingSphere();
        mesh.castShadow = true;
        mesh.receiveShadow = true;
        mesh.position.set(x, y, z);
        mesh.name = 'box3';
        mesh.weight = 1;
        mesh.cost = 1;
        mesh.time = 0.1;
        mesh.spots = [
            {id: 1, snap_distance: snapDistance, type: 'type9', position: new THREE.Vector3(0, 0.25, 0)},
            {id: 2, snap_distance: snapDistance, type: 'type9', position: new THREE.Vector3(0, -0.25, 0)},
            {id: 3, snap_distance: snapDistance, type: 'type9', position: new THREE.Vector3(0.25, 0, 0)},
            {id: 4, snap_distance: snapDistance, type: 'type9', position: new THREE.Vector3(-0.25, 0, 0)},
            {id: 5, snap_distance: snapDistance, type: 'type9', position: new THREE.Vector3(0, 0, 0.25)},
            {id: 6, snap_distance: snapDistance, type: 'type9', position: new THREE.Vector3(0, 0, -0.25)},
        ];
        resolve(mesh);
    });
}


export function makeObjectSafetyNet(x, y, z) {
    return new Promise((resolve) => {
        new MTLLoader().load(require('@/assets/models/safety_net.mtl'), (materials) => {
            materials.preload();
            new OBJLoader().setMaterials(materials).load(require('@/assets/models/safety_net.obj'), (obj) => {
                const mesh = obj.children[0];

                const material = new THREE.MeshLambertMaterial({
                    color: defaultColors['net'],
                    // map: platform2Texture,
                });
                mesh.material = material;

                mesh.geometry.computeBoundingBox();
                mesh.geometry.computeBoundingSphere();
                mesh.castShadow = true;
                mesh.receiveShadow = true;
                mesh.position.set(x, y, z);
                mesh.name = 'net';
                mesh.weight = 15;  // kg
                mesh.cost = 88;    // $
                mesh.time = 0.3;
                mesh.spots = [

                ];
                resolve(mesh);
            });
        });
    });
}


export function makeObjectAlien(x, y, z) {
    return new Promise((resolve) => {
        const geometry = new THREE.BoxGeometry(0.5, 0.3, 0.5);
        const material = new THREE.MeshLambertMaterial({
            color: defaultColors['alien'],
            // map: box2Texture,
        });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.geometry.computeBoundingBox();
        mesh.geometry.computeBoundingSphere();
        mesh.castShadow = true;
        mesh.receiveShadow = true;
        mesh.position.set(x, y, z);
        mesh.makeNextMove = makeNextMove;
        mesh.weight = 1;
        mesh.cost = 1;
        mesh.time = 0.1;
        mesh.spots = [];

        mesh.name = 'alien';
        mesh.goals = [];
        mesh.cargo = 'empty';
        mesh.health = alienFullHealthLevel;

        resolve(mesh);
    });
}

